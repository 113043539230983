import React, { FC, useEffect, useState } from "react";
import { AssetsService } from "../../../services/assets/Assets";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";
import Table from "../../../components/tables/Table";
import TableItem from "../../../components/tables/TableItem";
import { Play, RefreshCw, XCircle } from "react-feather";
import { TruncateString } from "../../../utils/text/TruncateString";
import { addToast } from "../../../state/toast/ToastSlice";
import SearchTable from "../../../components/inputs/search/SearchTable";
import { RootState } from "../../../state/store";

const WindowsServices = ({ assetId }) => {
  const [search, setSearch] = useState("");
  const [formLoading, setFormLoading] = useState(true);
  const [serviceData, setServiceData] = useState([]);
  const [filteredServiceData, setFilteredServiceData] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();
  const dispatch = useDispatch();

  useEffect(() => {
    setFormLoading(true);

    const fetchData = async () => {
      const serviceData = await service.fetchNinjaServices(companyId, assetId);
      setServiceData(serviceData);
      setFilteredServiceData(serviceData);
      setFormLoading(false);
    };

    fetchData();
  }, [assetId]);

  const changeState = async (serviceName: string, state: string) => {
    await service.changeStateNinjaService(
      companyId,
      assetId,
      serviceName,
      state
    );

    dispatch(
      addToast({
        description: "Het statusverzoek is naar het apparaat gestuurd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearch(value);
    filterServices(value);
  };

  const filterServices = (value: string) => {
    const filteredServices = serviceData.filter((service) =>
      service.displayName.toLowerCase().includes(value.toLowerCase())
    );
    setFilteredServiceData(filteredServices);
  };

  const tableHeaders: object = ["Naam", "Start type", "Status", "Acties"];

  return (
    <div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <SearchTable
            handleChange={handleChange}
            placeholder="Service zoeken..."
            value={search}
          />
          <Table header={tableHeaders}>
            {filteredServiceData?.map((service: any, index) => (
              <tr key={index}>
                <TableItem>
                  {<TruncateString text={service.displayName} limit={30} />}
                </TableItem>
                <TableItem>{service.startType}</TableItem>
                <TableItem>{service.state}</TableItem>
                <TableItem>
                  <div className="flex gap-1">
                    {service.state === "STOPPED" ? (
                      <button
                        className="text-green-500 hover:text-green-700"
                        onClick={() => changeState(service.name, "START")}
                      >
                        <Play size={16} />
                      </button>
                    ) : (
                      <button
                        className="text-red-500 hover:text-red-700"
                        onClick={() => changeState(service.name, "STOP")}
                      >
                        <XCircle size={16} />
                      </button>
                    )}

                    <button
                      className="hover:text-blue-500"
                      onClick={() => changeState(service.name, "RESTART")}
                    >
                      <RefreshCw size={16} />
                    </button>
                  </div>
                </TableItem>
              </tr>
            ))}
          </Table>
        </>
      )}
    </div>
  );
};

export default WindowsServices;
