import React, { useState } from "react";
import Cmc from "../../images/cmc-group-f.png";
import { ChevronLeft, ChevronRight } from "react-feather";
import { Layout, Menu } from "antd";
import { useDispatch } from "react-redux";
import { setSideBarOpen } from "../../state/notification/NotificationSlice";

const { Sider } = Layout;

type MenuItem = {
  key: string;
  icon: React.ReactNode;
  label: React.ReactElement;
  children?: MenuItem[];
};

const DesktopNav = ({ middleMenuItems }: { middleMenuItems: MenuItem[] }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const dispatch = useDispatch();

  const toggleSidebar = () => {
    const newSidebarState = !sidebarOpen;
    setSidebarOpen(newSidebarState);
    dispatch(setSideBarOpen(newSidebarState));
  };

  const siderStyle: React.CSSProperties = {
    overflowY: "auto",
    overflowX: "hidden",
    height: "100vh",
    insetInlineStart: 0,
    top: 0,
    bottom: 0,
    scrollbarWidth: "thin",
    scrollbarColor: "unset",
    display: "flex",
    backgroundColor: "#1d4ed8",
  };

  return (
    <Sider
      collapsible
      collapsed={!sidebarOpen}
      onCollapse={() => toggleSidebar()}
      style={siderStyle}
      trigger={null}
      className="hidden md:flex"
    >
      <div
        className={`h-full flex flex-col justify-between items-center ${
          sidebarOpen ? "w-[200px]" : ""
        }`}
      >
        <div className="flex flex-col w-full">
          <button
            className={`py-4 font-bold ${sidebarOpen ? "ml-1" : ""}`}
            onClick={() => toggleSidebar()}
          >
            <img className="px-2" src={Cmc} alt="CMC" />
          </button>
        </div>
        <Menu
          mode="inline"
          items={middleMenuItems}
          style={{ borderRight: "0px" }}
        />
        <div></div>
      </div>
      <button
        onClick={() => toggleSidebar()}
        className={`absolute -right-4 top-1/2 transform -translate-y-1/2 bg-blue-700 text-white py-3 rounded-r-xl ${
          sidebarOpen ? "hover:-right-3" : "hover:-right-5 hover:pl-1"
        } transition-all`}
      >
        {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
      </button>
    </Sider>
  );
};

export default DesktopNav;
