import { Button, ConfigProvider } from "antd";
import React, { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useTranslation } from "react-i18next";

type Props = {
  text: string;
  icon?: ReactNode;
  form?: boolean;
  disabled?: boolean;
  onClick?: () => void; // Corrected onClick type
};

const FormButton: FC<Props> = ({ text, icon, form, onClick, disabled }) => {
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);
  const { t } = useTranslation();
  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            // defaultBg: darkMode ? "#transparent" : "transparent",
            defaultHoverBg: darkMode ? "#334155" : "#f3f4f6",
            colorText: darkMode ? "#cbd5e1" : "#000",
            defaultHoverColor: darkMode ? "#cbd5e1" : "#000",
            defaultActiveBg: darkMode ? "#334155" : "#f3f4f6",
            defaultActiveColor: darkMode ? "#cbd5e1" : "#000",
            colorBorder: darkMode ? "#475569" : "#d1d5db",
            defaultHoverBorderColor: darkMode ? "#475569" : "#d1d5db",
            borderRadius: 8,
          },
        },
      }}
    >
      <Button
        block
        disabled={disabled}
        htmlType={form ? "submit" : "button"}
        onClick={onClick}
      >
        {icon && <span className="mr-3">{icon}</span>}
        <span>{t(text)}</span>
      </Button>
    </ConfigProvider>
  );
};

export default FormButton;
