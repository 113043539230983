import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { setAside } from "../../state/component/AsideSlice";
import { Tooltip } from "antd";

type Props = {
  users: [];
};

const ProjectUsers: FC<Props> = ({ users }) => {
  const dispatch = useDispatch();
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );

  const maxDisplayedUsers = 3;
  const displayedUsers = users.slice(0, maxDisplayedUsers);
  const remainingUserCount = users.length - maxDisplayedUsers;

  const viewDetail = (users: []) => {
    dispatch(
      setAside({
        active: true,
        component: "projectUsers",
        data: { users: users },
        crumb: "Project gebruikers",
      })
    );
  };

  return (
    <div className="flex justify-end">
      <div className="flex -space-x-3">
        {displayedUsers.map((user, index) => (
          <Tooltip title={user.name}>
            <div
              key={index}
              className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 border-2 border-white dark:border-slate-800"
            >
              <span className="font-medium text-gray-600 dark:text-gray-300 cursor-default">
                {user.name.charAt(0)}
              </span>
            </div>
          </Tooltip>
        ))}
        {remainingUserCount > 0 && (
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-blue-700 rounded-full border-2 border-white dark:border-slate-800">
            <span className="font-medium text-white cursor-default">
              +{remainingUserCount}
            </span>
          </div>
        )}
        <div
          className="flex items-center ml-4 cursor-pointer"
          onClick={() => viewDetail(users)}
        >
          <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-green-500 rounded-full border-2 border-white dark:border-slate-800">
            <span className="font-medium text-white">+</span>
          </div>
          <span className="ml-2">Gebruiker toevoegen</span>
        </div>
      </div>
    </div>
  );
};

export default ProjectUsers;
