import React, { useEffect, useState } from "react";
import { Select, Tooltip } from "antd";
import { TarifService } from "../../services/simcards/Tarifs";
import CurrencyFormatter from "../../utils/text/CurrencyFormatter";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

const tarifService = new TarifService();

const SelectTarif = ({ onSelect, defaultValue, type }) => {
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [tarifs, setTarifs] = useState([""]);

  useEffect(() => {
    tarifService.getTarifs(type, companyId).then((res) => {
      const result = Object.groupBy(res, ({ trf_type }) => trf_type);
      setTarifs(result);
    });
  }, []);

  return (
    <Select
      showSearch
      defaultValue={defaultValue}
      style={{ width: "100%" }}
      onChange={onSelect}
      optionFilterProp="label"
      id="5"
      options={Object.keys(tarifs).map((key) => ({
        label: (
          <span className=" font-bold text-slate-950 dark:text-slate-200">
            {key}
          </span>
        ),
        title: key,
        options: Object.keys(tarifs[key]).map((trf) => ({
          label: (
            <div className="flex justify-between items-center">
              <p>{tarifs[key][trf].trf_naam}</p>
              {tarifs[key][trf].price ? (
                <>
                  {/* <p>Level: {tarifs[key][trf].levels.levels.level}</p> */}
                  <p>
                    <CurrencyFormatter price={tarifs[key][trf].price} />
                  </p>
                </>
              ) : (
                ""
              )}
            </div>
          ),
          value: tarifs[key][trf].trf_id,
        })),
      }))}
    />
  );
};

export default SelectTarif;
