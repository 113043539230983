import React, { FC } from "react";
import EmployeeThumb from "../EmployeeThumb";
import Typography from "../typography/Typography";
import Time from "../typography/Time";
import DateFormatted from "../typography/Date";

type ChatBubble = {
  message: string;
  author: {};
  time: string;
  name: string;
  isAuthor?: boolean;
};

const ChatBubble: FC<ChatBubble> = ({
  message,
  author,
  time,
  name,
  isAuthor = true,
}) => {
  return (
    <div
      className={`flex items-end gap-2 5 px-6 py-2 ${
        !isAuthor ? "" : "flex-row-reverse"
      }`}
    >
      {author ? (
        <EmployeeThumb image={author.med_foto} name={author.med_naam} />
      ) : (
        <div className={`flex`}>
          <div className="relative inline-flex items-center justify-center w-9 h-9 overflow-hidden bg-gray-200 rounded-full">
            <span className="font-medium text-gray-600">
              {name?.charAt(0) ?? ""}
            </span>
          </div>
        </div>
      )}

      <div
        className={`flex flex-col w-full max-w-[80%] leading-1.5 px-4 pt-2 pb-3 border-gray-200 ${
          !isAuthor
            ? "bg-slate-200 text-gray-800 dark:bg-slate-800 dark:text-gray-100 rounded-e-2xl rounded-ss-2xl"
            : "bg-blue-700 text-white dark:text-white rounded-s-2xl rounded-tr-2xl"
        }`}
      >
        <div className="flex items-center justify-between">
          <span className="font-semibold">
            {author ? author.med_naam : name}
          </span>
          <div className="space-x-2">
            <Time time={time} />
          </div>
        </div>
        <Typography tag="p" color="noColor" type="label">
          {message}
        </Typography>
      </div>
    </div>
  );
};

export default ChatBubble;
