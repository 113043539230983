import React, { ReactNode, CSSProperties, createElement } from "react";
import { useTranslation } from "react-i18next";

// Type/Interface to avoid unwanted tags such as 'divs' e.g.
type TagVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
  | "p"
  | "span"
  | "label";

type TypographyProps = {
  tag: TagVariants;
  children: ReactNode | string;
  type: keyof typeof style;
  color?: keyof typeof colors;
  addClass?: string | null;
};

const style = {
  smallest: "text-[10px] opacity-75",
  label: "text-sm",
  default: "text-base",
  semibold: "dark:text-slate-200 text-slate-800 font-semibold",
  bold: "dark:text-slate-200 text-slate-800 font-bold",
  faded: "opacity-50",
  accent: "text-blue-700",
  subtitle: "text-neutral-700 font-bold dark:text-stone-300 text-xl",
  title: "text-neutral-700 font-bold text-xl dark:text-stone-300",
  header:
    "text-neutral-700 dark:text-stone-300 font-semibold text-4xl leading-none",
};

const colors = {
  default: "dark:text-slate-300 text-slate-600",
  white: "text-white",
  noColor: "",
};

const Typography = ({
  tag,
  children,
  type,
  color = "default",
  addClass,
}: TypographyProps & CSSProperties) => {
  const { t } = useTranslation();

  return createElement(
    tag,
    { className: `${addClass} ${style[type]} ${colors[color]}` },
    typeof children === "string" ? t(children) : children
  );
};

export default Typography;
