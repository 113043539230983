import apiClient from "../../../api-client";

export class NotificationsService {
  public async getUserNotifications(userId: number, companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/notifications/user/${userId}`
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching notifications: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateNotification(
    notificationId: number,
    companyId: number,
    is_read: boolean
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/notifications/${notificationId}`,
        {
          is_read: is_read,
        }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating notification: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }
}
