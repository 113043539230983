import apiClient from "../../../api-client";
import { AppendSearchParams } from "../../utils/AppendSearchParams";

export class AssetsService {
  public async getAssets(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserAssets(companyId: number, userId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/user/${userId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getAssetTickets(companyId: number, assetId: number) {
    // Implement when types are added to tickets on new backend
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/asset/tickets/${assetId}`
    // );
    // return await response.json();

    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tickets`,
        { type: "HARDWARE", type_value: assetId }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async assetDetail(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all assets: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async deleteAsset(companyId: number, assetId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting asset: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async rebootNinjaDevice(companyId: number, assetId: number) {
    // Still needs to be checked if it works
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/reboot`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error rebooting device: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async fetchNinjaServices(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/services`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device services: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async changeStateNinjaService(
    companyId: number,
    assetId: number,
    serviceName: string,
    state: string
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/services/${serviceName}/state`,
        {
          state: state,
        }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error changing device service state: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchNinjaApps(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/apps`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device apps: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchNinjaActivities(companyId: number, assetId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/ninja/activities`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device activities: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async fetchOrginisationDevices() {
    // Add to the new backend after table for integrations is added
    const response = await fetch(
      AppendSearchParams(
        `${process.env.GATSBY_PORTAL_API_URL}/api/asset/organisation/devices`,
        {}
      )
    );
    return await response.json();
  }

  public async addHardware(companyId: number, asset: Object) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets`,
        {
          serialNumber: asset.serialNumber,
          name: asset.name,
          category: asset.category,
          deviceId: String(asset.deviceId),
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating asset: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateAssetUser(
    companyId: number,
    assetId: number,
    newUserId: number,
    userName: string
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/adduser/${newUserId}`,
        { userName: userName }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from asset: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createTag(companyId: number, assetId: number, tag: string) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/tags`,
        {
          tag: tag,
        }
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating tag on: ${assetId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async deleteTag(companyId: number, assetId: number, tagId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/tags/${tagId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching device activities: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async removeUser(companyId: number, assetId: number) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/assets/${assetId}/removeuser`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from asset: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateAssetsUser(assetIds: string[], userId = null) {
    // Add to the new backend (and replace updateAssetUser by this call)
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/asset/updateassetsuser`;
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ assetIds, userId }),
    };
    const response = await fetch(url, requestOptions);
    if (!response.ok) {
      throw new Error("Failed to remove assets from user");
    }
    return await response.json();
  }
}
