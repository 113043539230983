import { FC } from "react";
import TextHeader from "../../../components/headers/TextHeader";
import React from "react";
import EditUserForm from "../../../components/forms/users/EditUserForm";

const UserCreateContainer: FC = () => {
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Gebruiker" />
      <EditUserForm user={{}}></EditUserForm>
    </div>
  );
};

export default UserCreateContainer;
