import apiClient from "../../../api-client";

export class TarifService {
  public async getTarif(id: number, companyId: number) {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tariffs/${id}`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching tariff with id: ${id}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getTarifs(type: string, companyId: number) {
    try {
      const url = `${
        process.env.GATSBY_SSO_BACKEND_API_URL
      }/company/${companyId}/tariffs${type ? `?type=${type}` : ""}`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all tariffs: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async addOption(body: object, companyId: number) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tariffs/options`,
        body
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding option: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async removeOption(optionId: number, companyId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tariffs/options/${optionId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting option: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async addService(body: object, companyId: number) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tariffs/services`,
        body
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding option: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async removeService(serviceId: number, companyId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/tariffs/services/${serviceId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting option: ${error.response?.statusText || error.message}`
      );
    }
  }
}
