import apiClient from "../../../api-client";

interface Project {
  [x: string]: any;
}

export class ProjectsService {
  public async getProjects(companyId: number): Promise<Project[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/project`
    // );
    // const data = await response.json();
    // return data;

    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all projects: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getProject(
    projectId: number,
    companyId: number
  ): Promise<Project[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/project/${projectId}`
    // );
    // const data = await response.json();
    // return data;

    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/${projectId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching project with id: ${projectId}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserProjects(
    id: number,
    companyId: number
  ): Promise<Project[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/user/projects/${id}`
    // );
    // const data = await response.json();
    // return data;

    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/user/${id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching projects for user: ${id}: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createProject(
    newProjectData: Partial<Project>,
    companyId: number
  ) {
    // const url = `${process.env.GATSBY_PORTAL_API_URL}/api/project/create`;
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(newProjectData),
    // };
    // const response = await fetch(url, requestOptions);
    // return await response.json();

    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects`,
        newProjectData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating project: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async addAssignments(
    assignments: object[],
    projFaseId: number,
    projFaseRegel_id: number,
    companyId: number
  ) {
    // const url = `${process.env.GATSBY_PORTAL_API_URL}/api/project/addassignments`;
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(assignmentsData),
    // };

    // const response = await fetch(url, requestOptions);
    // return await response.json();

    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/phase/${projFaseId}/assignments`,
        { assignments, phaseRuleId: projFaseRegel_id }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding assignments: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async finishAssignment(
    projFaseId: number,
    projFaseRegel_id: number,
    companyId: number
  ) {
    // const url = `${process.env.GATSBY_PORTAL_API_URL}/api/project/finishassignment`;
    // const requestOptions = {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(assignmentsData),
    // };

    // const response = await fetch(url, requestOptions);
    // return await response.json();

    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/projects/phase/${projFaseId}/assignments/${projFaseRegel_id}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding assignments: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  // public async deleteProject(id: number) {
  //   const url = `${process.env.GATSBY_PORTAL_API_URL}/api/project/delete/${id}`;
  //   const requestOptions = {
  //     method: "DELETE",
  //     headers: { "Content-Type": "application/json" },
  //   };

  //   const response = await fetch(url, requestOptions);
  //   return await response.json();
  // }
}
