import React, { FC, ReactNode, useEffect, useState } from "react";
import { ShoppingCart, X } from "react-feather";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { clearData, setAside } from "../../state/component/AsideSlice";

//usable components
import TicketDetailContainer from "../../containers/tickets/TicketDetailContainer";
import SimDetailContainer from "../../containers/telecom/sim/SimDetailContainer";
import LoadingSpinner from "../buttons/LoadingSpinner";
import UserDetailContainer from "../../containers/settings/users/UserDetailContainer";
import FiltersSidebar from "../products/FiltersSidebar";
import ShoppingCartAside from "../../containers/shop/cart/ShoppingCartAside";
import GroupDetailContainer from "../../containers/settings/groups/GroupDetailContainer";
import GroupCreateContainer from "../../containers/settings/groups/GroupCreateContainer";
import UserCreateContainer from "../../containers/settings/users/UserCreateContainer";
import BranchDetailContainer from "../../containers/settings/branches/BranchDetailContainer";
import BranchCreateContainer from "../../containers/settings/branches/BranchCreateContainer";
import OrderDetailContainer from "../../containers/orders/OrderDetailContainer";
import AssetDetailContainer from "../../containers/hardware/HardwareDetailContainer";
import AddHardware from "../../containers/hardware/AddHardware";
import ProjectDetailContainer from "../../containers/projects/ProjectDetailContainer";
import SimOnboarding from "../../containers/projects/SimOnboarding";
import HardwareOnboarding from "../forms/users/HardwareOnboarding";
import LicenseOnboarding from "../forms/users/LicenseOnboarding";
import LicenseOffboarding from "../forms/users/LicenseOffboarding";
import SimOffboarding from "../forms/users/SimOffboarding";
import HardwareOffboarding from "../forms/users/HardwareOffboarding";
import RequestNewSimcards from "../../containers/telecom/sim/RequestNewSimcards";
import Crumbs from "../navigation/Crumbs";
import CmcTask from "../forms/project/CmcTask";
import CompanyLicenseDetail from "../../containers/licenses/CompanyLicenseDetail";
import AddLicense from "../../containers/licenses/AddLicense";
import CreateTicketContainer from "../../containers/tickets/CreateTicketContainer";
import AddProjectUser from "../../containers/projects/AddProjectUser";
import CreateLicenseRequest from "../../containers/licenses/CreateLicenseRequest";
import ActivateSimRequest from "../../containers/telecom/sim/ActivateSimRequest";
import LicenseRequestDetail from "../../containers/licenses/LicenseRequestDetail";

const components = {
  default: LoadingSpinner,
  ticket: TicketDetailContainer,
  ticketCreate: CreateTicketContainer,
  simDetail: SimDetailContainer,
  projectUsers: AddProjectUser,
  userDetail: UserDetailContainer,
  userCreate: UserCreateContainer,
  groupDetail: GroupDetailContainer,
  groupCreate: GroupCreateContainer,
  branchDetail: BranchDetailContainer,
  branchCreate: BranchCreateContainer,
  orderDetail: OrderDetailContainer,
  assetDetail: AssetDetailContainer,
  addHardware: AddHardware,
  addLicense: AddLicense,
  requestLicense: CreateLicenseRequest,
  licenseDetail: CompanyLicenseDetail,
  licenseRequestDetail: LicenseRequestDetail,
  projectDetail: ProjectDetailContainer,
  telecomOnboard: SimOnboarding,
  hardwareOnboard: HardwareOnboarding,
  licenseOnboard: LicenseOnboarding,
  telecomOffboarding: SimOffboarding,
  hardwareOffboarding: HardwareOffboarding,
  licenseOffboarding: LicenseOffboarding,
  cmcTask: CmcTask,
  filtersSidebar: FiltersSidebar,
  shoppingCart: ShoppingCartAside,
  requestNewSimcards: RequestNewSimcards,
  ActivateSimRequest: ActivateSimRequest,
};

type Props = {
  children?: ReactNode;
  active?: boolean;
  close?: any;
};

const Aside: FC<Props> = ({ children, active, close }) => {
  const asideStore = useSelector((state: RootState) => state.aside);
  const [crumbs, setCrumbs] = useState({});

  const dispatch = useDispatch();
  const closeAside = () => {
    dispatch(clearData());
    dispatch(
      setAside({
        active: false,
        component: asideStore.component,
        data: asideStore.data,
      })
    );
  };

  const goBack = (component: string, crumb: string) => {
    dispatch(
      setAside({
        active: true,
        component: component,
        data: asideStore.data,
        crumb: crumb,
      })
    );
  };

  const Component = components[asideStore.component];

  return (
    <>
      <button
        className={`z-20 fixed top-0 left-0 bg-black opacity-25 w-full h-full ${
          asideStore.active ? "block" : "hidden"
        }`}
        onClick={closeAside}
      ></button>
      <aside
        className={`z-40 fixed overflow-auto right-0 top-0 h-full dark:bg-gray-900 dark:text-slate-300 text-slate-900 transition-all ${
          asideStore.active
            ? "w-full block"
            : "right-[-100%] invisible pointer-events-none"
        } max-w-screen-md bg-white`}
      >
        <button onClick={closeAside} className="float-right m-4 text-red-700">
          <X />
        </button>
        <div className="p-10 md:p-20">
          {asideStore.crumbs ? (
            <Crumbs links={asideStore.crumbs} onclick={goBack} />
          ) : (
            ""
          )}

          {asideStore.data ? (
            <Component data={asideStore.data} />
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </aside>
    </>
  );
};

export default Aside;
