import React, { FC, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import type { UploadFile, UploadProps } from "antd";
import { Button, message, Upload } from "antd";
import Typography from "../../typography/Typography";
import { useTranslation } from "react-i18next";

const { Dragger } = Upload;

type Props = {
  fileTypes?: string[];
  onUpload: (files: UploadFile[]) => void;
};

const FileUpload: FC<Props> = ({ fileTypes, onUpload }) => {
  const { t } = useTranslation();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [uploading, setUploading] = useState(false);

  const props: UploadProps = {
    name: "file",
    multiple: true,
    onRemove: (file) => {
      setFileList((prevFileList) =>
        prevFileList.filter((f) => f.uid !== file.uid)
      );
    },
    beforeUpload: (file) => {
      const isAllowedType = fileTypes ? fileTypes.includes(file.type) : true;
      if (isAllowedType) {
        setFileList((prevFileList) => [...prevFileList, file]);
      } else {
        message.error(`${file.name} is not a supported file type`);
      }
      return false; // Prevent automatic upload
    },
  };

  const handleUpload = () => {
    setUploading(true);
    onUpload(fileList);
    setUploading(false);
  };

  return (
    <div>
      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          {t("Klik hier of sleep een bestand naar dit gebied om te uploaden.")}
        </p>
        <p className="ant-upload-hint">
          {t(
            "Upload hier de ingevulde versies van de documenten die u gedownload heeft."
          )}
        </p>
      </Dragger>
      <div className="mt-1">
        <Typography tag={"p"} type={"label"}>
          <strong>!</strong>{" "}
          {t(
            "Zorg ervoor dat u de nodige documenten heeft geupload voor u het formulier indient"
          )}
        </Typography>
      </div>
      <Button
        onClick={handleUpload}
        // disabled={fileList.length === 0}
        loading={uploading}
        className="mt-1"
      >
        {uploading ? t("Aan het uploaden") : t("Indienen")}
      </Button>
    </div>
  );
};

export default FileUpload;
