import React, { FC, ReactElement } from "react";
import { ConfigProvider, Popover } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { useTranslation } from "react-i18next";

type Props = {
  form: ReactElement;
  button: ReactElement;
  title?: string;
};

const PopoverForm: FC<Props> = ({ form, button, title }) => {
  const { t } = useTranslation();
  const darkMode = useSelector((state: RootState) => state.theme.darkMode);

  return (
    <ConfigProvider
      theme={{
        components: {
          Popover: {
            colorBgElevated: darkMode ? "#1e293b" : "#fff",
            colorTextHeading: darkMode ? "#cbd5e1" : "#000",
            boxShadowSecondary:
              "0 10px 20px rgba(0, 0, 0, 0.3), 0 6px 6px rgba(0, 0, 0, 0.1)",
          },
        },
      }}
    >
      <Popover content={form} title={t(title)} trigger="click">
        {button}
      </Popover>
    </ConfigProvider>
  );
};

export default PopoverForm;
