import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SimcardService } from "../../services/simcards/Simcards";
import { SumObjects } from "../../utils/numbers/SumObject";

interface SimdataState {
  loading: boolean;
  simData: object;
  simDataTotal: number;
  error: string | undefined;
}

const initialState: SimdataState = {
  loading: false,
  simData: [],
  simDataTotal: 0,
  error: "",
};

const SimService = new SimcardService();

export const fetchSimData = createAsyncThunk(
  "sim/fetchBruto",
  (props: string[]) => {
    return SimService.getBruto(props);
  }
);

const simDataSlice = createSlice({
  name: "simData",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSimData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchSimData.fulfilled,
      (state, action: PayloadAction<object>) => {
        state.loading = false;
        state.simData = action.payload;
        state.simDataTotal = SumObjects(action.payload.results, "sum(`value`)");
        state.error = state.error = "";
      }
    );
    builder.addCase(fetchSimData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default simDataSlice.reducer;
