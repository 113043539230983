import React, { FC } from "react";
import { ChevronRight } from "react-feather";
import { useTranslation } from "react-i18next";

type Props = {
  links: object[];
  onclick: any;
};

const Crumbs: FC<Props> = ({ links, onclick }) => {
  const { t } = useTranslation();
  // Move state outside of the rendering logic
  const handleClick = (component, title) => {
    // Handle click events here
    onclick(component, title);
  };

  return (
    <>
      {Object.keys(links).length > 1 && (
        <nav className="flex mb-4" aria-label="Breadcrumb">
          <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
            {Object.keys(links).map((item, key) => (
              <li key={key}>
                <div className="flex items-center">
                  {key !== 0 ? <ChevronRight size={14} /> : ""}
                  <button
                    className="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white"
                    onClick={() =>
                      handleClick(links[item].component, links[item].title)
                    }
                  >
                    {t(links[item].title)}
                  </button>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
};

export default Crumbs;
