import React, { ChangeEvent, FC, useState } from "react";
import { Form } from "antd";
import { CouponService } from "../../../services/coupons/CouponService";
import { useDispatch } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import { createCoupon } from "../../../state/coupon/CouponSlice";
import FormButton from "../../buttons/FormButton";
import FormField from "../../inputs/text/FormField";

interface Props {
  user: any;
}

const AddCouponForm: FC<Props> = ({ user }) => {
  const [form] = Form.useForm();
  const service = new CouponService();
  const dispatch = useDispatch();

  const createCouponUser = async (value: any) => {
    const couponData = await service.createCoupon(user.geb_id, value);

    // Call the createCoupon action from your Redux slice
    dispatch(createCoupon(couponData));

    dispatch(
      addToast({
        description: "De coupon is aangemaakt.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  return (
    <Form
      labelAlign="left"
      layout="vertical"
      form={form}
      onFinish={createCouponUser}
    >
      <div>
        <FormField
          type="number"
          label="Bedrag (euro)"
          placeholder="0"
          name="bedrag"
          form={form}
          isRequired={true}
        />
        <FormButton
          form={true}
          text={Object.keys(user).length === 0 ? "Aanmaken" : "Opslaan"}
        />
      </div>
    </Form>
  );
};

export default AddCouponForm;
