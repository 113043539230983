import React, { FC, useEffect, useState } from "react";
import { Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../tables/Table";
import TableRow from "../../tables/TableRow";
import TableItem from "../../tables/TableItem";
import { setAside } from "../../../state/component/AsideSlice";
import { RootState } from "../../../state/store";
import Typography from "../../typography/Typography";
import { AssetsService } from "../../../services/assets/Assets";
import { Edit } from "react-feather";
import TextHeader from "../../headers/TextHeader";
import { ProjectsService } from "../../../services/project/Projects";
import { setCurrentProject } from "../../../state/project/ProjectSlice";

const HardwareOffboarding: FC = () => {
  const [assets, setAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);
  const task = useSelector((state: RootState) => state.aside.data.task);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const assetService = new AssetsService();
  const projectService = new ProjectsService();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    setLoading(true);
    try {
      const userAssets = await assetService.getUserAssets(
        companyId,
        task.metadata[0].value
      );
      setAssets(userAssets);
    } catch (error) {
      console.error("Error fetching assets:", error);
    } finally {
      setLoading(false);
    }
  };

  const viewDetail = (asset: string[]) => {
    dispatch(
      setAside({
        active: true,
        component: "assetDetail",
        data: { asset: asset },
        crumb: "Hardware",
      })
    );
  };

  const handleSubmit = async () => {
    dispatch(
      setAside({
        active: 0,
      })
    );

    try {
      const project = await projectService.finishAssignment(
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(setCurrentProject(project));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  const handleSelectAsset = (assetId: string, isChecked: boolean) => {
    setSelectedAssets((prevSelected) =>
      isChecked
        ? [...prevSelected, assetId]
        : prevSelected.filter((id) => id !== assetId)
    );
  };

  const handleRemoveSelectedAssets = async () => {
    try {
      await assetService.updateAssetsUser(selectedAssets);

      // Remove the selected assets from the assets list
      setAssets((prevAssets) =>
        prevAssets.filter((asset) => !selectedAssets.includes(asset.ordSer_id))
      );

      setSelectedAssets([]); // Clear the selected assets
    } catch (error) {
      console.error("Error removing assets:", error);
    }
  };

  return (
    <div className="overflow-hidden">
      <div className="mb-2">
        <TextHeader title={"Hardware"} />
        <Typography tag={"p"} type={"default"}>
          U kan de hardware beheren door op de detail knop te drukken.
        </Typography>
        <Typography tag={"p"} type={"label"}>
          Zorg ervoor dat er geen hardware meer aan de gebruiker gelinkt is.
        </Typography>
      </div>
      <div className="col-span-2 border dark:border-slate-800 rounded-lg h-full">
        <Table header={["Selecteer", "Product", "Acties"]}>
          {assets.map((asset) => (
            <TableRow key={asset.ordSer_id}>
              <TableItem type="tiny">
                <Checkbox
                  checked={selectedAssets.includes(asset.ordSer_id)}
                  onChange={(e) =>
                    handleSelectAsset(asset.ordSer_id, e.target.checked)
                  }
                />
              </TableItem>
              <TableItem type="tiny">
                {asset.order_product?.ordPro_naam}
              </TableItem>
              <TableItem type="tiny">
                <button onClick={() => viewDetail(asset)}>
                  <Edit size={18} />
                </button>
              </TableItem>
            </TableRow>
          ))}
        </Table>
      </div>
      <div className="mt-3 flex space-x-3">
        <Button disabled={assets.length > 0} onClick={handleSubmit}>
          Taak afronden
        </Button>
        <Button
          onClick={handleRemoveSelectedAssets}
          disabled={selectedAssets.length === 0}
        >
          Verwijder geselecteerde hardware
        </Button>
      </div>
    </div>
  );
};

export default HardwareOffboarding;
