import React, { useState } from "react";
import Form from "../../../components/forms/Form";
import Typography from "../../../components/typography/Typography";
import BranchSelect from "../../../components/branches/BranchSelect";
import { TicketService } from "../../../services/tickets/Tickets";
import { useDispatch, useSelector } from "react-redux";
import { addToast } from "../../../state/toast/ToastSlice";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";

const RequestNewSimcards = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new TicketService();
  const dispatch = useDispatch();

  const handleSelect = (event, option) => {
    setAddress(option.label);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const description = "Simkaarten worden binnenkort opgestuurd.";

    await service.createTicketZendesk(companyId, {
      subject: `Aanvraag nieuwe simkaarten`,
      description: `Aanvraag voor nieuwe simkaarten op te sturen naar: ${address}`,
      deparment: "Telecom",
    });

    dispatch(
      addToast({
        description: ["Aanvraag nieuwe simkaarten", { address: address }],
        style: "success",
      })
    );

    setLoading(false);
  };

  return (
    <Form
      buttonLabel="Aanvraag indienen"
      handleSubmit={handleSubmit}
      loading={loading}
    >
      <div className="block mb-4">
        <Typography tag="span" type="default">
          Simkaarten worden opgestuurd
        </Typography>
      </div>

      <Typography tag="span" type="label">
        Selecteer een leveradres
      </Typography>

      <BranchSelect
        loading={loading}
        handleSelect={handleSelect}
        defaultValue={t("Selecteer adres")}
      />
    </Form>
  );
};

export default RequestNewSimcards;
