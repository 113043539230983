import React, { ChangeEvent, FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BranchesService } from "../../services/branches/Branches";
import { addOptions, setBranches } from "../../state/branch/BranchSlice";
import { RootState } from "../../state/store";
import SelectBox from "../inputs/selectbox/SelectBox";
import { useTranslation } from "react-i18next";

type Props = {
  handleSelect: (event: ChangeEvent<HTMLInputElement>) => void;
  defaultValue: string;
  loading: boolean;
};

const BranchSelect: FC<Props> = ({ handleSelect, defaultValue, loading }) => {
  const { t } = useTranslation();
  // const [branchOptions, setBranchOptions] = useState([]);
  const branchOptions = useSelector((state: RootState) => state.branch.options);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const branchService = new BranchesService();
  const dispatch = useDispatch();

  useEffect(() => {
    Promise.all([fetchBranchOptions(), fetchBranches()]);
  }, []);

  const fetchBranchOptions = async () => {
    branchService
      .getBranchOptions(companyId)
      .then((data) => {
        dispatch(addOptions(data));
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  };

  const fetchBranches = async () => {
    branchService
      .getBranches(companyId)
      .then((data) => {
        dispatch(setBranches(data));
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  };

  return (
    <SelectBox
      onChange={handleSelect}
      defaultValue={t(defaultValue)}
      options={branchOptions}
      loading={loading}
      search={false}
    />
  );
};

export default BranchSelect;
