export class MailService {
  public async sendMail(to, subject, view, formData) {
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/mail/send`;

    formData.append("to", to);
    formData.append("subject", subject);
    formData.append("view", view);

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
  }
}
