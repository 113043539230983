exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-hardware-overview-tsx": () => import("./../../../src/pages/hardware/overview.tsx" /* webpackChunkName: "component---src-pages-hardware-overview-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-licenses-overview-tsx": () => import("./../../../src/pages/licenses/overview.tsx" /* webpackChunkName: "component---src-pages-licenses-overview-tsx" */),
  "component---src-pages-order-overview-tsx": () => import("./../../../src/pages/order/overview.tsx" /* webpackChunkName: "component---src-pages-order-overview-tsx" */),
  "component---src-pages-project-detail-overview-tsx": () => import("./../../../src/pages/project/detail/overview.tsx" /* webpackChunkName: "component---src-pages-project-detail-overview-tsx" */),
  "component---src-pages-project-overview-tsx": () => import("./../../../src/pages/project/overview.tsx" /* webpackChunkName: "component---src-pages-project-overview-tsx" */),
  "component---src-pages-settings-branches-overview-tsx": () => import("./../../../src/pages/settings/branches/overview.tsx" /* webpackChunkName: "component---src-pages-settings-branches-overview-tsx" */),
  "component---src-pages-settings-groups-overview-tsx": () => import("./../../../src/pages/settings/groups/overview.tsx" /* webpackChunkName: "component---src-pages-settings-groups-overview-tsx" */),
  "component---src-pages-settings-settings-overview-tsx": () => import("./../../../src/pages/settings/settings/overview.tsx" /* webpackChunkName: "component---src-pages-settings-settings-overview-tsx" */),
  "component---src-pages-settings-users-overview-tsx": () => import("./../../../src/pages/settings/users/overview.tsx" /* webpackChunkName: "component---src-pages-settings-users-overview-tsx" */),
  "component---src-pages-shop-cart-overview-tsx": () => import("./../../../src/pages/shop/cart/overview.tsx" /* webpackChunkName: "component---src-pages-shop-cart-overview-tsx" */),
  "component---src-pages-shop-categories-overview-tsx": () => import("./../../../src/pages/shop/categories/overview.tsx" /* webpackChunkName: "component---src-pages-shop-categories-overview-tsx" */),
  "component---src-pages-shop-category-overview-tsx": () => import("./../../../src/pages/shop/category/overview.tsx" /* webpackChunkName: "component---src-pages-shop-category-overview-tsx" */),
  "component---src-pages-shop-cyod-categories-overview-tsx": () => import("./../../../src/pages/shop/cyod/categories/overview.tsx" /* webpackChunkName: "component---src-pages-shop-cyod-categories-overview-tsx" */),
  "component---src-pages-shop-cyod-overview-tsx": () => import("./../../../src/pages/shop/cyod/overview.tsx" /* webpackChunkName: "component---src-pages-shop-cyod-overview-tsx" */),
  "component---src-pages-shop-cyod-products-overview-tsx": () => import("./../../../src/pages/shop/cyod/products/overview.tsx" /* webpackChunkName: "component---src-pages-shop-cyod-products-overview-tsx" */),
  "component---src-pages-shop-product-overview-tsx": () => import("./../../../src/pages/shop/product/overview.tsx" /* webpackChunkName: "component---src-pages-shop-product-overview-tsx" */),
  "component---src-pages-telecom-sim-overview-tsx": () => import("./../../../src/pages/telecom/sim/overview.tsx" /* webpackChunkName: "component---src-pages-telecom-sim-overview-tsx" */),
  "component---src-pages-tickets-overview-tsx": () => import("./../../../src/pages/tickets/overview.tsx" /* webpackChunkName: "component---src-pages-tickets-overview-tsx" */),
  "component---src-pages-user-dashboard-overview-tsx": () => import("./../../../src/pages/user/dashboard/overview.tsx" /* webpackChunkName: "component---src-pages-user-dashboard-overview-tsx" */)
}

