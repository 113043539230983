import { Link } from "gatsby";
import React, { FC, ReactNode } from "react";

type RowProps = {
  children: ReactNode;
  key?: string;
  to?: string;
  onClick?: () => void;
};

const TableRow: FC<RowProps> = ({ children, key, to, onClick }) => {
  const rowContent = to ? (
    <Link to={to} className="contents">
      {children}
    </Link>
  ) : (
    children
  );

  return (
    <tr
      key={key}
      onClick={onClick}
      className={`hover:bg-slate-50 dark:hover:bg-slate-600 ${
        onClick ? "cursor-pointer" : ""
      }`}
    >
      {rowContent}
    </tr>
  );
};

export default TableRow;
