import React, { FC } from "react";
import TextHeader from "../../components/headers/TextHeader";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Typography from "../../components/typography/Typography";
import CurrencyFormatter from "../../utils/text/CurrencyFormatter";
import Tabs from "../../components/tabs/Tabs";
import RequestLicenses from "./RequestLicenses";
import CurrentLicenses from "./CurrentLicenses";
import { useTranslation } from "react-i18next";

type License = {
  license: any;
};

const CompanyLicenseDetail: FC<License> = ({ license }) => {
  const { t } = useTranslation();
  const licenseState = useSelector(
    (state: RootState) => state.aside.data.license
  );

  const tabs = [
    {
      nav: "Huidige licenties",
      component: [<CurrentLicenses license={license} />],
    },
    {
      nav: "Meer aanvragen",
      component: [<RequestLicenses />],
    },
  ];

  return (
    <div>
      <TextHeader title={licenseState.lic_licentie} />
      <Typography tag={"p"} type={"default"}>
        {licenseState.lic_informatie}
      </Typography>
      <div className="mt-1">
        <CurrencyFormatter price={licenseState.prijs} />
      </div>
      <div className="mt-1 flex justify-between">
        <Typography tag={"p"} type={"default"}>
          {t("Licenties")}: {licenseState.aantal}
        </Typography>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default CompanyLicenseDetail;
