import apiClient from "../../../api-client";

interface Branch {
  ves_straatNaam: string;
  ves_straatNr: string;
  ves_gemeente: string;
  ves_postcode: string;
  ves_bed_id: number;
  ves_geb_id: number;
  ves_land: string;
  ves_name: string;
}

export class BranchesService {
  public async getBranches(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/branches`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching branches: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getBranchOptions(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/branches/options`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching branch options: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateBranch(
    companyId: number,
    branchId: number,
    updatedData: Partial<Branch>
  ) {
    // const url = `${process.env.GATSBY_PORTAL_API_URL}/api/settings/branches/${branchId}`;
    // const requestOptions = {
    //   method: "PATCH",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify(updatedData),
    // };

    // const response = await fetch(url, requestOptions);
    // return await response.json();

    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/branches/${branchId}`,
        updatedData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating branch: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async createBranch(companyId: number, newBranchData: Partial<Branch>) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/branches`,
        newBranchData
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating branch: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async deleteBranch(companyId: number, branchId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/branches/${branchId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting branch: ${error.response?.statusText || error.message}`
      );
    }
  }
}
