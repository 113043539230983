import React, { FC, FormEvent, useState } from "react";
import Form from "../Form";
import DateInput from "../../inputs/dates/DateInput";
import Typography from "../../typography/Typography";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../state/store";
import { TicketService } from "../../../services/tickets/Tickets";
import { addToast } from "../../../state/toast/ToastSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { updateAside } from "../../../state/component/AsideSlice";
import { SimcardService } from "../../../services/simcards/Simcards";

type props = {};

const ConvertToPrepaid: FC<props> = () => {
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(dayjs());
  const ticketService = new TicketService();
  const simService = new SimcardService();
  const sim = useSelector((state: RootState) => state.aside.data);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const dispatch = useDispatch();

  const onChange = (date, dateString) => {
    setDate(date);
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const prop = {
      sim_status: "Omschakeling Pay&Go",
    };

    const formatedDate = date.format("DD/MM/YYYY");

    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.sim.key }));

    await simService.updateSimcard(
      sim.sim.sim_id,
      {
        ...prop,
      },
      companyId
    );

    await ticketService.createTicketZendesk(companyId, {
      subject: `Omschakelen naar Pay&Go op ${formatedDate}`,
      description: `Ticket ${sim.sim.sim_unique_nr} omschakelen naar Pay&Go vanaf ${formatedDate}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim.sim,
    });

    dispatch(
      addToast({
        description: [
          "Omschakelen naar Pay&Go",
          {
            sim_unique_nr: sim.sim.sim_unique_nr,
          },
        ],
        position: "bottomRight",
        style: "success",
      })
    );

    setLoading(false);
  };

  return (
    <>
      <Typography tag="span" type="label" addClass="mb-5 block">
        swap to prepaid
      </Typography>

      <Typography tag="span" type="label" addClass="block">
        Vanaf welke datum?
      </Typography>
      <Form
        buttonLabel="Indienen"
        handleSubmit={handleSubmit}
        gridSize="grid-cols-2"
        loading={loading}
      >
        <DateInput onChange={onChange} value={date} />
        <div className="mb-2"></div>
      </Form>
    </>
  );
};

export default ConvertToPrepaid;
