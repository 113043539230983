import React from "react";
import { Phone, MessageSquare, Globe } from "react-feather";
import Typography from "../Typography";

const RatePlanInfo = ({ ratePlan }) => {
  return (
    <ul className="flex flex-col sm:flex-row">
      <li className="inline-flex items-center">
        <Phone size={16} className="mr-1" />
        <Typography tag="span" type="label">
          {ratePlan.trf_bellen ? ratePlan.trf_bellen : " - "}
        </Typography>
      </li>
      <li className="inline-flex items-center ml-4">
        <MessageSquare size={16} className="mr-1" />
        <Typography tag="span" type="label">
          {ratePlan.trf_sms ? ratePlan.trf_sms : " - "}
        </Typography>
      </li>
      <li className="inline-flex items-center ml-4">
        <Globe size={16} className="mr-1" />
        <Typography tag="span" type="label">
          {ratePlan.trf_surfData ? ratePlan.trf_surfData : " - "}
        </Typography>
      </li>
    </ul>
  );
};

export default RatePlanInfo;
