import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CouponState {
  coupons: object[];
}

const initialState: CouponState = {
  coupons: [],
};

const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCoupons: (state, action: PayloadAction<object[]>) => {
      state.coupons = action.payload;
    },
    updateCoupon: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingGroup = state.coupons.find(
        (coupon) => coupon.cou_geb_id === id
      );
      if (existingGroup) {
        Object.assign(existingGroup, updatedData);
      }
    },
    createCoupon: (state, action: PayloadAction<object>) => {
      state.coupons.push(action.payload);
    },
    deleteCoupon: (state, action: PayloadAction<number>) => {
      state.coupons = state.coupons.filter(
        (coupon) => coupon?.cou_geb_id !== action.payload
      );
    },
  },
});

export const { setCoupons, updateCoupon, createCoupon, deleteCoupon } =
  couponSlice.actions;

export default couponSlice.reducer;
