import React, { FC } from "react";
import { Send } from "react-feather";

interface ButtonProps {
  text?: string;
  position: keyof typeof ButtonPositions;
  icon?: keyof typeof Icons;
}

const ButtonPositions = {
  left: "left-4",
  right: "right-4",
};

const Icons = {
  send: <Send />,
};

const FloatingButton: FC<ButtonProps> = ({ position, icon }) => {
  return (
    <button
      className={`absolute top-1/2 -translate-x-1/2 -translate-y-1/2 hover:text-gray-600 dark:hover:text-gray-400 ${ButtonPositions[position]}`}
    >
      {Icons[icon]}
    </button>
  );
};

export default FloatingButton;
