import apiClient from "../../../api-client";
interface User {
  geb_voornaam: string;
  geb_naam: string;
  geb_isActive: number;
  geb_isAdmin: boolean;
  geb_isRapport: boolean;
}

export class UsersService {
  public async getUsers(companyId: number, search?: string): Promise<User[]> {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users${
          search ? `?search=${search}` : ""
        }`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all users: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserNames(companyId: number, searchTerm: string = "") {
    try {
      const response = await apiClient.get(
        `${
          process.env.GATSBY_SSO_BACKEND_API_URL
        }/company/${companyId}/users/names${
          searchTerm ? `?search=${searchTerm}` : ""
        }`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching usernames: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserRelations(
    companyId: number,
    userId: number,
    relations: string[]
  ) {
    try {
      const response = await apiClient.get(
        `${
          process.env.GATSBY_SSO_BACKEND_API_URL
        }/company/${companyId}/users/${userId}/relations?relations=${relations.join(
          ","
        )}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user relations: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  // NOT USED
  public async getUser(companyId: number, userId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users${userId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching user: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateUser(
    companyId: number,
    userId: number,
    updatedData: Partial<User>
  ) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users/${userId}`,
        updatedData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating user: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async removeFromGroup(companyId: number, userId: number) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users/${userId}/removefromgroup`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from group: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createUser(companyId: number, newUserData: Partial<User>) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users`,
        newUserData
      );
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating user: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async deleteUser(companyId: number, userId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/users/${userId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error deleting user: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getUserSims(companyId: number, userId: number) {
    try {
      const url = `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/user/${userId}`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all user sims: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getAuthUser() {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/auth/user`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error getting user: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async logout(): Promise<void> {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/auth/logout`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error logging out: ${error.response?.statusText || error.message}`
      );
    }
  }
}
