import React from "react";
import { Eye, EyeOff } from "react-feather";
import Typography from "../typography/Typography";
import { getTimeAgo } from "../../utils/dates/TimeAgoUtil";
import { Tooltip } from "antd";
import { NotificationsService } from "../../services/notification/Notifications";
import { RootState } from "../../state/store";
import { useSelector, useDispatch } from "react-redux";
import Cta from "../buttons/Cta";
import { navigate } from "gatsby";
import { Capitalize } from "../../utils/text/Capitalize";
import { useTranslation } from "react-i18next";
import { setNotificationCount } from "../../state/notification/NotificationSlice";
import { setAside } from "../../state/component/AsideSlice";

interface Notification {
  id: number;
  title: string;
  message: string;
  createdAt: string;
  is_read: boolean;
  action?: string;
  action_value?: string;
}

interface NotificationsProps {
  notifications: Notification[];
}

const NotificationList: React.FC<NotificationsProps> = ({ notifications }) => {
  const [localNotifications, setLocalNotifications] =
    React.useState<Notification[]>(notifications);
  const notificationService = new NotificationsService();
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const updateNotificationStatus = async (
    notificationId: number,
    is_read: boolean
  ) => {
    try {
      await notificationService.updateNotification(
        notificationId,
        companyId,
        is_read
      );
      setLocalNotifications((prev) =>
        prev.map((notification) =>
          notification.id === notificationId
            ? { ...notification, is_read }
            : notification
        )
      );

      dispatch(setNotificationCount(is_read ? -1 : 1));
    } catch (error) {
      console.error("Failed to update notification:", error);
    }
  };

  const handleActionClick = (notification: Notification) => {
    if (!notification.is_read) {
      updateNotificationStatus(notification.id, true);
    }
    if (notification.action === "NAVIGATE") {
      navigate(notification.action_value!);
    }
    if (notification.action === "ASIDE") {
      // Can't think of a better solution atm, the setAside will be different for different subjects
      if (notification.title === "Added to ticket") {
        dispatch(
          setAside({
            active: true,
            component: "ticket",
            data: { ticket: { id: notification.action_value } },
            crumb: "Ticket",
          })
        );
      }
    }
  };

  return (
    <div
      className="h-full md:h-80 overflow-y-auto"
      style={{
        scrollbarWidth: "thin",
      }}
    >
      {localNotifications.length > 0 ? (
        localNotifications.map((notification) => (
          <div
            key={notification.id}
            className="p-4 border-b border-gray-200 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-slate-700 relative"
          >
            <div
              className={`absolute ${
                !notification.is_read
                  ? "text-blue-700 hover:text-blue-500"
                  : "text-red-500 hover:text-red-700"
              } top-3 right-3 h-2 w-2 rounded-full -translate-x-[120%]`}
            >
              {!notification.is_read ? (
                <Tooltip title="Mark as read" placement="rightBottom">
                  <button
                    onClick={() =>
                      updateNotificationStatus(notification.id, true)
                    }
                  >
                    <Eye size={16} />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Mark as unread" placement="rightBottom">
                  <button
                    onClick={() =>
                      updateNotificationStatus(notification.id, false)
                    }
                  >
                    <EyeOff size={16} />
                  </button>
                </Tooltip>
              )}
            </div>

            <div className="flex items-center gap-1">
              <Typography tag={"p"} type={"semibold"}>
                {notification.title}
              </Typography>
              {!notification.is_read && (
                <div className="bg-red-500 h-2 w-2 rounded-full"></div>
              )}
            </div>
            <Typography tag={"p"} type={"default"}>
              {notification.message}
            </Typography>
            <span className="text-xs text-gray-500">
              {getTimeAgo(notification.created_at)}
            </span>
            {notification.action_value && (
              <Cta
                text={Capitalize(t(notification.action))}
                onClick={() => handleActionClick(notification)}
              />
            )}
          </div>
        ))
      ) : (
        <p className="p-4 text-gray-500">No notifications</p>
      )}
    </div>
  );
};

export default NotificationList;
