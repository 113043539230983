import React, { useEffect, useState } from "react";
import { X } from "react-feather";
import Tabs from "../tabs/Tabs";
import NotificationList from "./NotificationList";
import { NotificationsService } from "../../services/notification/Notifications";
import { RootState } from "../../state/store";
import { useDispatch, useSelector } from "react-redux";
import {
  hideNotifications,
  setNotificationCount,
} from "../../state/notification/NotificationSlice";
import Typography from "../typography/Typography";
import { Capitalize } from "../../utils/text/Capitalize";
import { useTranslation } from "react-i18next";

const NotificationModal: React.FC = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const notificationService = new NotificationsService();
  const notificationState = useSelector(
    (state: RootState) => state.notification
  );
  const dispatch = useDispatch();

  const user_id = useSelector((state: RootState) => state.profile.userId);
  const company_id: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );

  useEffect(() => {
    if (company_id) {
      fetchNotifications();
    }
  }, [company_id]);

  const fetchNotifications = async () => {
    setLoading(true);
    try {
      const notifications = await notificationService.getUserNotifications(
        user_id,
        company_id
      );
      setNotifications(notifications.result);
      const unreadNotifications = notifications.result.filter(
        (notification) => notification.is_read === 0
      );
      dispatch(setNotificationCount(unreadNotifications.length));
    } catch (error) {
      console.error("Error fetching notifications:", error);
    } finally {
      setLoading(false);
    }
  };

  const uniqueTypes = Array.from(
    new Set(notifications.map((notification) => notification.type))
  );

  const tabs = [
    {
      nav: t("All"),
      component: <NotificationList notifications={notifications} />,
    },
    ...uniqueTypes.map((type) => ({
      nav: t(Capitalize(type)),
      component: (
        <NotificationList
          notifications={notifications.filter(
            (notification) => notification.type === type
          )}
        />
      ),
    })),
  ];

  const setInvisible = async () => {
    dispatch(hideNotifications());
  };

  return (
    <>
      {!loading && (
        <div
          className={`fixed z-[100] top-12 h-full w-screen md:right-3 md:m-4 md:mb-0 md:w-96 md:h-auto bg-white dark:bg-slate-800 shadow-2xl md:rounded-lg overflow-hidden transition-all origin-top duration-300 ${
            notificationState.isVisible ? "scale-100" : "scale-0"
          }`}
        >
          <div className="bg-blue-700 text-white p-2 flex justify-between items-center">
            <Typography tag={"h2"} type={"title"} color="white">
              Notifications
            </Typography>
            <button
              className="text-sm text-gray-200 hover:text-white"
              onClick={() => setInvisible()}
            >
              <X />
            </button>
          </div>
          <div>
            <Tabs tabs={tabs} />
          </div>
        </div>
      )}
    </>
  );
};

export default NotificationModal;
