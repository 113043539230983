import React, { Children, useState } from "react";
import Accordeon from "../../../components/accordeon/Accordeon";

import ChangeTarif from "../../../components/forms/simcards/ChangeTarif";
import SwapSimcard from "../../../components/forms/simcards/SwapSimcard";
import AddOption from "../../../components/forms/simcards/AddOption";
import ConvertToPrepaid from "../../../components/forms/simcards/ConvertToPrepaid";

import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import Mpay from "../../../components/forms/simcards/Mpay";
import TempDisable from "../../../components/forms/simcards/TempDisable";
import DisableSimcard from "../../../components/forms/simcards/DisableSimcard";
import { useTranslation } from "react-i18next";

const SimActions = () => {
  const { t } = useTranslation();
  const sim = useSelector((state: RootState) => state.aside.data.sim);
  const [laoding, setLoading] = useState(false);

  const panels = [
    {
      key: "1",
      label: t("Ruil simkaart"),
      children: <SwapSimcard currentValue={sim.sim_unique_nr} />,
    },
    {
      key: "2",
      label: t("Omschakelen naar Pay & Go"),
      children: <ConvertToPrepaid />,
    },
    {
      key: "3",
      label: t("Opties beheren"),
      children: <AddOption currentValue="" currentTarifId="" />,
    },
    {
      key: "4",
      label: t("Wijzig tariefplan"),
      children: (
        <ChangeTarif
          currentValue={sim.tariefplan ? sim.tariefplan.trf_naam : ""}
          currentTarifId={sim.trf_id}
        />
      ),
    },
    {
      key: "5",
      label: t("Diensten"),
      children: <Mpay />,
    },
    {
      key: "6",
      label: t("Tijdelijk buiten dienst zetten"),
      children: <TempDisable />,
    },
    {
      key: "7",
      label: <span className=" text-red-700">{t("Simkaart opzeggen")}</span>,
      children: <DisableSimcard />,
    },
  ];

  return (
    <div>
      <Accordeon panels={panels} />
    </div>
  );
};

export default SimActions;
