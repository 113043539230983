import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProfileState {
  firstName: string;
  lastName: string;
  email: string;
  userId?: number;
}

const initialState: ProfileState = {
  firstName: "",
  lastName: "",
  email: "",
  userId: undefined,
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setFirstName: (state, action: PayloadAction<string>) => {
      state.firstName = action.payload;
    },
    setLastName: (state, action: PayloadAction<string>) => {
      state.lastName = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setProfile: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        email: string;
        userId: number;
      }>
    ) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.email = action.payload.email;
      state.userId = action.payload.userId;
    },
    clearProfile: (state) => {
      state = initialState;
    },
  },
});

export const { setFirstName, setLastName, setEmail, setProfile, clearProfile } =
  profileSlice.actions;

export default profileSlice.reducer;
