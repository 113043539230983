import React, { useEffect, useState } from "react";
import { AssetsService } from "../../services/assets/Assets";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import TicketTable from "../../components/tickets/TicketTable";
import TicketForm from "../tickets/TicketForm";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { TicketService } from "../../services/tickets/Tickets";

const HardwareTickets = (assetId) => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState([]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new TicketService();

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      await fetchTickets();
      setLoading(false);
    };

    fetchData();
  }, [assetId]);

  const fetchTickets = async () => {
    // const tickets = await service.getAssetTickets(companyId, assetId.assetId);
    const tickets = await service.getTickets(
      companyId,
      "HARDWARE",
      assetId.assetId
    );
    setTickets(tickets.zendesk);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <TicketTable tickets={tickets} />
        </>
      )}

      <div>
        <TicketForm type="HARDWARE" type_value={assetId.assetId} />
      </div>
    </div>
  );
};

export default HardwareTickets;
