import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { stat } from "fs";

interface AsideSliceState {
  active: boolean | null;
  component: string;
  data: Record<string, any>;
  crumbs: Record<string, any>;
}

const initialState: AsideSliceState = {
  active: null,
  component: "default",
  data: {},
  crumbs: {},
};

const setCrumbs = (state, currentComponent, crumb) => {
  state.crumbs = {
    ...state.crumbs,
    [currentComponent]: {
      component: currentComponent,
      title: crumb,
    },
  };
};

const asideSlice = createSlice({
  name: "aside",
  initialState,
  reducers: {
    setComponent: (state, action: PayloadAction<string>) => {
      state.component = action.payload;
    },
    setAside: (
      state,
      action: PayloadAction<{
        active: boolean;
        component: string;
        data?: Record<string, any>;
        crumb?: string;
      }>
    ) => {
      state.active = action.payload.active;
      if (action.payload.data) {
        const keys = Object.keys(action.payload.data);
        keys.forEach((key) => {
          state.data = {
            ...state.data,
            [key]: {
              ...action.payload.data[key],
              component: action.payload.component,
            },
          };
        });
        state.component = action.payload.component;
        if (action.payload.crumb) {
          setCrumbs(state, action.payload.component, action.payload.crumb);
        }
      } else {
        state.crumbs = {};
      }
    },
    updateAside: (
      state,
      action: PayloadAction<{ props: Record<string, any>; object: string }>
    ) => {
      const { props, object } = action.payload;
      const data = state.data[object];

      if (data) {
        Object.keys(props).forEach((key) => {
          if (data[key]) {
            data[key] = props[key];
          }
        });
      }
    },
    clearData: (state) => {
      state.data = {};
      state.crumbs = {};
    },
  },
});

export const { setComponent, setAside, updateAside, clearData } =
  asideSlice.actions;
export default asideSlice.reducer;
