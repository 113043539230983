const CalculateTotalPrice = (cart, cyod) => {
  let totalPrice = 0; // Initialize with cart total price

  // Calculate total price for selected products
  if (Object.keys(cyod.selectedProducts).length > 0) {
    Object.values(cyod.selectedProducts).forEach((selectedProduct) => {
      const product = selectedProduct.product;
      totalPrice += Number(product.price) / 1.21;

      selectedProduct.accessories?.forEach((item) => {
        totalPrice += Number(item.accessory.sale_price) / 1.21;
      });
    });
  }
  totalPrice += cart.totalPrice;

  return Math.abs(totalPrice);
};

export default CalculateTotalPrice;
