import React, { FC, useEffect } from "react";
import TextHeader from "../../components/headers/TextHeader";
import Tag from "../../components/tags/Tag";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import Typography from "../../components/typography/Typography";
import CurrencyFormatter from "../../utils/text/CurrencyFormatter";
import { Capitalize } from "../../utils/text/Capitalize";
import FetchImage from "../../components/images/FetchImage";
import DateFormatted from "../../components/typography/Date";

type Order = {
  order: any;
};

const OrderDetailContainer: FC<Order> = ({ order }) => {
  const orderState = useSelector((state: RootState) => state.aside.data);

  return (
    <div>
      <div className="flex items-center justify-between flex-wrap">
        <div className="flex items-center gap-1">
          <TextHeader title="Bestelling" />
          <Tag
            style={orderState.order.ord_status}
            label={orderState.order.ord_status}
          />
        </div>
        <div className="w-24 text-right">
          <DateFormatted date={orderState.order.created_at} />
          {/* <Tag
            style={"neutral"}
            label={new Date(orderState.order.created_at).toLocaleDateString()}
          /> */}
        </div>
      </div>
      <div className="grid grid-cols-3 text-center py-5 border-b dark:border-gray-700">
        <div className="font-bold">
          <Typography tag={"p"} type={"label"}>
            Totaal
          </Typography>
          <CurrencyFormatter
            tag="excl. BTW"
            price={orderState.order.ord_totaal}
          />
        </div>
        {orderState.order.payments.map((payment) => (
          <div key={payment.id}>
            <Typography tag={"p"} type={"label"}>
              {Capitalize(payment.ordPay_type)}
            </Typography>
            <CurrencyFormatter tag="excl. BTW" price={payment.ordPay_amount} />
          </div>
        ))}
      </div>
      <div className="grid grid-cols-2 text-center py-5">
        <div>
          <Typography tag={"p"} type={"label"}>
            Leveradres
          </Typography>
          <div>
            {orderState.order.ord_straatNaam} {orderState.order.ord_straatNr}
          </div>
          <div>
            {orderState.order.ord_gemeente}, {orderState.order.ord_land}
          </div>
        </div>
        <div>
          <Typography tag={"p"} type={"label"}>
            Factuuradres
          </Typography>
          <div>
            {orderState.order.ord_straatNaam} {orderState.order.ord_straatNr}
          </div>
          <div>
            {orderState.order.ord_gemeente}, {orderState.order.ord_land}
          </div>
        </div>
      </div>
      {orderState.order.order_products.map((product) => (
        <div key={product.ordPro_id}>
          <div className="text-center md:text-left grid grid-cols-2 md:grid-cols-5 items-center border-t dark:border-gray-700 py-4">
            <div className="mx-auto col-span-2 md:col-span-1">
              <FetchImage productId={product.ordPro_pro_id} style={"cart"} />
            </div>
            <div className="col-span-3">
              <Typography tag={"h1"} type={"semibold"}>
                {product.ordPro_naam}
              </Typography>
              <div className="flex items-center justify-center md:justify-start mt-3">
                <div>
                  <input
                    value={product.ordPro_aantal}
                    type="number"
                    id={`quantity_${product.ordPro_id}`}
                    className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="1"
                    required
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="col-span-2 md:col-span-1 mx-auto text-center mt-3 md:mt-0">
              <Typography tag={"h1"} type={"semibold"}>
                <CurrencyFormatter
                  tag="excl. BTW"
                  price={product.ordPro_prijs}
                />
              </Typography>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default OrderDetailContainer;
