import { navigate } from "gatsby";

export const AsideOrNavigate = (type: string) => {
  const map = {
    telecom: {
      type: "aside",
      component: "telecomOnboard",
    },
    hardware: {
      type: "aside",
      component: "hardwareOnboard",
    },
    licenties: {
      type: "aside",
      component: "licenseOnboard",
    },
    telecomoffboarding: {
      type: "aside",
      component: "telecomOffboarding",
    },
    hardwareoffboarding: {
      type: "aside",
      component: "hardwareOffboarding",
    },
    licentiesoffboarding: {
      type: "aside",
      component: "licenseOffboarding",
    },
    cmctaak: {
      type: "aside",
      component: "cmcTask",
    },
  };

  const t = map[type.toLocaleLowerCase()];

  if (t.type === "aside") {
    return t.component;
  } else {
    navigate(t.component);
    return null;
  }
};
