import React, { useState } from "react";
import Form from "../Form";
import DateRange from "../../inputs/dates/DateRange";
import Typography from "../../typography/Typography";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../state/store";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { TicketService } from "../../../services/tickets/Tickets";

const TempDisable = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([dayjs()]);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const sim = useSelector((state: RootState) => state.aside.data);
  const ticketService = new TicketService();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);

    const dates = {
      startDate: date[0].format("DD/MM/YYYY"),
      endDate: date.length > 1 ? date[1].format("DD/MM/YYYY") : "∞",
    };

    const prop = {
      sim_status: "Tijdelijk buiten dienst",
    };

    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.sim.key }));

    setLoading(false);

    ticketService.createTicketZendesk(companyId, {
      subject: `Opzeggen optie ${sim.sim.sim_options[0].trf_naam}`,
      description: `Opzeggen optie: ${sim.sim.sim_options[0].trf_naam} voor simkaart ${sim.sim.sim_unique_nr}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim.sim,
    });
  };

  const handleDate = (date, dateString) => {
    setDate(date);
  };

  return (
    <div>
      <Typography tag="span" type="label" addClass="mb-5 block">
        De simkaart wordt op inactief geplaatst
      </Typography>
      <Form
        buttonLabel="indienen"
        handleSubmit={handleSubmit}
        loading={loading}
        gridSize="grid-col-2"
      >
        <div className="block mt-2 mb-2">
          <Typography tag="label" type="label">
            Start en einddatum
          </Typography>
          <div className="block">
            <DateRange onChange={handleDate} value={date} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default TempDisable;
