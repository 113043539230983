export class CouponService {
  public async getCoupon() {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_API_URL}/api/coupon`
    );
    return await response.json();
  }

  public async getUserCoupon(id: number) {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_API_URL}/api/user/coupon/${id}`
    );
    const data = await response.json();
    return data;
  }

  public async createCoupon(gebId, amount) {
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/coupon`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ geb_id: gebId, bedrag: amount.bedrag }),
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
  }

  public async createCouponsGroup(bedGrId) {
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/coupon/group`;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ bedGr_id: bedGrId }),
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
  }

  public async updateCoupon(couponId: number, amount: number, status: boolean) {
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/coupon/${couponId}`;
    const requestOptions = {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        amount: amount,
        status: status,
      }),
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
  }

  public async deleteCoupon(gebId: number) {
    const url = `${process.env.GATSBY_PORTAL_API_URL}/api/coupon/${gebId}`;
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    const response = await fetch(url, requestOptions);
    return await response.json();
  }

  //   public async getCoupons() {
  //     const response = await fetch(
  //         `${process.env.GATSBY_PORTAL_API_URL}/api/coupon`
  //       );
  //       return await response.json();
  //   }
}
