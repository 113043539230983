import React, { FC, ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const TooltipArrow: FC<Props> = ({ children }) => {
  return (
    <div className="z-50 p-4  bg-white dark:bg-slate-900 rounded-lg max-w-46 border dark:border-slate-800">
      {children}
    </div>
  );
};

export default TooltipArrow;
