import React, { FC, useEffect, useReducer, useState } from "react";
import TextHeader from "../../components/headers/TextHeader";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import SelectBox from "../../components/inputs/selectbox/SelectBox";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import Tabs from "../../components/tabs/Tabs";
import ProductInfo from "./ProductInfo";
import { AssetsService } from "../../services/assets/Assets";
import { UsersService } from "../../services/users/Users";
import {
  createTag,
  deleteTag,
  updateHardware,
} from "../../state/hardware/HardwareSlice";
import { addToast } from "../../state/toast/ToastSlice";
import HardwareTickets from "./HardwareTickets";
import InputField from "../../components/inputs/text/InputField";
import Tag from "../../components/tags/Tag";
import { Trash2 } from "react-feather";
import UserSelect from "../../components/users/UserSelect";
import { useTranslation } from "react-i18next";

type Asset = {
  asset: any;
};

const AssetDetailContainer: FC<Asset> = ({ asset }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [tags, setTags] = useState<string>();
  const assetState = useSelector((state: RootState) => state.aside.data.asset);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const [assetData, setAssetData] = useState(assetState);
  const service = new UsersService();
  const assetService = new AssetsService();
  const dispatch = useDispatch();

  const [force, forceUpdate] = useReducer((x) => x + 1, 0);

  const tabs = [
    {
      nav: "Productinfo",
      component: [
        <ProductInfo
          asset={assetState}
          category={assetState.ordPro_categorie}
        />,
      ],
    },
    {
      nav: "Tickets",
      component: [<HardwareTickets assetId={assetState.ordSer_id} />],
    },
  ];

  useEffect(() => {
    setTags(assetState.tags);
  }, []);

  useEffect(() => {
    setLoading(true);
    setFormLoading(true);

    const fetchData = async () => {
      await fetchOptions();
      setTags(assetState.tags);

      setFormLoading(false);
      setLoading(false);
    };

    fetchData();
  }, [assetState]);

  const fetchOptions = async () => {
    const fetchedOptions = await service.getUserNames(companyId);
    setOptions(fetchedOptions);
    setAssetData(assetState);
  };

  const handleSelect = async (value: string, label: string) => {
    const asset = await assetService.updateAssetUser(
      companyId,
      assetState.ordSer_id,
      value,
      label.label
    );

    dispatch(updateHardware({ id: assetState.ordSer_id, updatedData: asset }));

    dispatch(
      addToast({
        description: "De gebruiker is gewijzigd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleInputKeyPress = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter" && inputValue.trim() !== "") {
      try {
        const tag = await assetService.createTag(
          companyId,
          // order_product_id: assetState.ordSer_ordPro_id,
          assetState.ordSer_id,
          inputValue.trim()
        );

        setTags([
          ...tags,
          {
            tagId: tag.tagId,
            hardwareId: assetState.ordSer_id,
            tag: inputValue.trim(),
          },
        ]);
        dispatch(
          createTag({
            hardwareId: assetState.ordSer_id,
            tag: inputValue.trim(),
          })
        );
        setInputValue("");
      } catch (error) {
        console.error("Error creating tag:", error);
      }
    }
  };

  const removeTag = async (tagId: number) => {
    try {
      await assetService.deleteTag(companyId, assetState.ordSer_id, tagId);

      const updatedTags = tags.filter((tag) => tag.id !== tagId);
      setTags(updatedTags);
      dispatch(deleteTag({ hardwareId: assetState.ordSer_id, tagId: tagId }));
    } catch (error) {
      console.error("Error deleting tag:", error);
    }
  };

  const handleRemoveUser = async () => {
    setLoading(true);
    try {
      const asset = await assetService.removeUser(
        companyId,
        assetState.ordSer_id
      );

      setAssetData({ ...assetData, ordSer_user_id: null, ordSer_username: "" });
      dispatch(
        updateHardware({ id: assetState.ordSer_id, updatedData: asset })
      );

      // setAssetData(asset);
      forceUpdate();

      dispatch(
        addToast({
          description: "Gebruiker verwijderd van het apparaat.",
          position: "bottomRight",
          style: "success",
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error removing user from asset:", error);
    }
  };

  return (
    <div>
      <div>
        <TextHeader
          title={assetState.ordPro_naam}
          subtitle={assetState.ordSer_serial}
        />
      </div>
      {formLoading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <div className="grid grid-cols-2 mt-5 gap-3">
            <UserSelect
              key={force}
              handleSelect={handleSelect}
              defaultValue={assetData.ordSer_user_id ?? "Selecteer gebruiker"}
              loading={loading}
            />
            <div>
              <button
                className="bg-red-500 text-white p-2 rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
                onClick={handleRemoveUser}
              >
                <Trash2 size={18} />
              </button>
            </div>
            <div className="col-span-2">
              <input
                className="border border-gray-300 dark:border-slate-600 dark:text-gray-300 text-sm rounded-lg block w-full px-2.5 py-1.5 bg-transparent dark:bg-gray-900 focus:outline-blue-700"
                type="text"
                placeholder={t("Voeg tags toe (druk op Enter)")}
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleInputKeyPress}
              />
              {tags[0].id !== null && (
                <div className="mt-2 flex gap-1">
                  {tags?.map((tag, index) => (
                    <div
                      className="hover:cursor-pointer"
                      onClick={() => removeTag(tag.id)}
                    >
                      <Tag key={index} style={"hover"} label={tag.tag} />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
          <Tabs tabs={tabs} />
        </div>
      )}
    </div>
  );
};

export default AssetDetailContainer;
