import React, { FC } from "react";
import Tabs from "../../components/tabs/Tabs";
import AddInternalLicense from "./AddInternalLicense";
import AddThirdPartyLicense from "./AddThirdPartyLicense";

const AddLicense: FC = () => {
  const tabs = [
    {
      nav: "CMC Licenties",
      component: [<AddInternalLicense />],
    },
    {
      nav: "Externe licenties",
      component: [<AddThirdPartyLicense />],
    },
  ];

  return <Tabs tabs={tabs} />;
};

export default AddLicense;
