import { AppendSearchParams } from "../../utils/AppendSearchParams";

// interface Category {
//     cat_id: number;
//     name: string;
//     description?: string;
//     parent_id?: number;
//     subcategories?: Category[] | null;
//     [x: string]: any;
//   }

interface ShopItem {}

export class ShopService {
  private readonly baseUrl: string = "https://shop.cmcgroup.be/api";

  public getCategories(): Promise<Category[]> {
    return fetch(`${this.baseUrl}/fetchcategories`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  public getCategoryItems(categoryName: string): Promise<ShopItem[]> {
    return fetch(`${this.baseUrl}/fetchproducts/category/${categoryName}`)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        return data;
      })
      .catch((error) => {
        console.warn(error);
      });
  }

  public async getProduct(slug: string): Promise<Product> {
    const response = await fetch(`${this.baseUrl}/fetchProduct/slug/${slug}`);
    return await response.json();
  }

  public async getProductImage(id: number): Promise<Image> {
    const response = await fetch(
      `${this.baseUrl}/fetchMainProductImage/product/${id}`
    );
    return await response.json();
  }
}
