import React, { FC, useState } from "react";
import { useSelector } from "react-redux";
import Typography from "../../typography/Typography";
import { RootState } from "../../../state/store";
import TextHeader from "../../headers/TextHeader";
import { Button, Radio, RadioChangeEvent, UploadFile } from "antd";
import FileUpload from "../../inputs/fileupload/FileUpload";
import BranchSelect from "../../branches/BranchSelect";
import SelectSimcard from "../../../organisms/simcards/SelectSimcard";
import SelectTarif from "../../../organisms/simcards/SelectTarif";
import InputField from "../../inputs/text/InputField";
import SelectBox from "../../inputs/selectbox/SelectBox";
import ToggleSwitch from "../../inputs/toggle/ToggleSwitch";
import dayjs from "dayjs";
import LOAMobileNL from "../../../files/LOA_mobile_NL.pdf";
import OverdrachtsFormulierNL from "../../../files/Overdrachtsdocument_Proximus_NL.pdf";
import IntekeningBudgetManager from "../../../files/BudgetManager_NL.pdf";
import DateInput from "../../inputs/dates/DateInput";
import { t } from "i18next";
import LoadingSpinner from "../../buttons/LoadingSpinner";

interface props {
  onSubmit: any;
  loading: boolean;
}

const SimOnboardingForm: FC<props> = ({ onSubmit, loading }) => {
  // const { simcards } = useSelector((state: RootState) => state.simcards);
  const [simcardDetails, setSimcardDetails] = useState([
    { sim: "", tariefplan: "", tariefplanId: "" },
  ]);
  const [eSim, setESim] = useState(false);
  const [budgetmanager, setBudgetmanager] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [transferType, setTransferType] = useState("Nieuwe nummer");
  const [gsmNumber, setGsmNumber] = useState(null);
  const [operator, setOperator] = useState("Proximus");
  const [subscriptionType, setSubscriptionType] = useState(null);

  const task = useSelector((state: RootState) => state.aside.data.task);
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const branchState = useSelector((state: RootState) => state.branch);

  const providerOptions = [
    { value: "Proximus", label: "Proximus" },
    { value: "Orange", label: "Orange" },
    { value: "Base", label: "Base" },
    { value: "Telenet", label: "Telenet" },
    { value: "Mobile Vikings", label: "Mobile Vikings" },
    { value: "Andere", label: "Andere" },
  ];

  const handleSimChange = (value: any, option: any, index: number) => {
    setSimcardDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[index].sim = value;
      return updatedDetails;
    });
  };

  const handleTariefplanChange = (value: any, option: any, index: number) => {
    setSimcardDetails((prevState) => {
      const updatedDetails = [...prevState];
      updatedDetails[index].tariefplan = option.label;
      updatedDetails[index].tariefplanId = value;
      return updatedDetails;
    });
  };

  const handleBranchSelect = (value: number) => {
    const selectedBranch = branchState.branches.find(
      (branch) => branch.ves_id === value
    );
    setSelectedBranch(selectedBranch);
  };

  const handleTransferType = (e: RadioChangeEvent) => {
    setTransferType(e.target.value);
  };

  const handleSubscriptionTypeChange = (e: RadioChangeEvent) => {
    setSubscriptionType(e.target.value);
  };

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve({ base64String: reader.result.split(",")[1], name: file.name });
      reader.onerror = (error) => reject(error);
    });

  const handleSubmit = async (files: UploadFile[]) => {
    const formData = new FormData();

    const base64Promises = files.map((file) => fileToBase64(file));
    const base64Strings = await Promise.all(base64Promises);

    base64Strings.forEach(({ base64String, name }) => {
      formData.append("files[]", base64String);
      formData.append("fileNames[]", name);
    });

    if (selectedBranch) {
      formData.append("branch", JSON.stringify(selectedBranch));
    }

    simcardDetails.forEach((detail, index) => {
      formData.append(`simcards[sim]`, simcardDetails[0].sim);
      formData.append(`simcards[tarif]`, simcardDetails[0].tariefplan);
      formData.append(`simcards[tarifId]`, simcardDetails[0].tariefplanId);
    });

    formData.append("date", selectedDate.format("DD-MM-YYYY"));
    formData.append("isESim", eSim ? "true" : "false");
    formData.append("isBudgetManager", budgetmanager ? "true" : "false");
    formData.append("user_id", project ? project.metadata[0].value : "");
    formData.append("transferType", transferType);

    if (transferType === "Overdracht nummer") {
      formData.append("gsm_number", gsmNumber);
      formData.append("operator", operator);
      formData.append("subscription_type", subscriptionType);
    }

    onSubmit(formData);
  };

  return (
    <>
      <div className={`${loading ? "animate-pulse" : ""}`}>
        <TextHeader
          title="Simkaarten"
          subtitle={"Nieuwe nummer of nummer overdragen"}
        />
        <Radio.Group
          onChange={handleTransferType}
          value={transferType}
          defaultValue={"Nieuwe nummer"}
        >
          <Radio value={"Nieuwe nummer"}>{t("Nieuwe nummer")}</Radio>
          <Radio value={"Overdracht nummer"}>{t("Overdracht nummer")}</Radio>
        </Radio.Group>

        {transferType === "Overdracht nummer" ? (
          <div className="mt-3 mb-2">
            <div className="mb-1">
              <Typography tag={"h1"} type={"semibold"}>
                Voor we beginnen
              </Typography>
              <Typography tag={"p"} type={"label"}>
                Voor de activatie zijn enkele documenten nodig:
              </Typography>
              <ul className="mt-2">
                <li className="list-disc ml-5">
                  <Typography tag={"p"} type={"default"}>
                    Download alvast de LOA als het nummer wordt overgedragen van
                    een andere operator en neem een kopie van de
                    identiteitskaart van de huidige titularis.
                  </Typography>
                </li>
                {operator === "Proximus" &&
                subscriptionType === "Abonnement" ? (
                  <li className="list-disc ml-5">
                    <Typography tag={"p"} type={"default"}>
                      Download het overdrachtsformulier van Proximus voor een
                      overdracht binnen Proximus.
                    </Typography>
                  </li>
                ) : (
                  ""
                )}
              </ul>
              <div className="mt-3 mb-5 flex gap-2">
                <Button href={LOAMobileNL} download="LOA_mobile_NL.pdf">
                  Download Letter Of Authorization
                </Button>
                {operator === "Proximus" &&
                subscriptionType === "Abonnement" ? (
                  <Button
                    href={OverdrachtsFormulierNL}
                    download="Overdrachtsdocument_Proximus_NL.pdf"
                  >
                    Download overdrachtsformulier Proximus
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 mb-5">
              <div>
                <Typography tag={"p"} type={"semibold"}>
                  Huidige GSM-nummer
                </Typography>
                <InputField
                  type={"text"}
                  name={"gsm_number"}
                  placeholder={""}
                  onChange={(e) => setGsmNumber(e.target.value)}
                />
              </div>
              <div>
                <Typography tag={"p"} type={"semibold"}>
                  Huidige operator
                </Typography>
                <SelectBox
                  search={true}
                  options={providerOptions}
                  defaultValue={"Proximus"}
                  onChange={(value) => setOperator(value)}
                />
              </div>
            </div>
            <div>
              <Typography tag={"p"} type={"semibold"}>
                Maak je gebruik van een herlaadkaart of en abonnement?
              </Typography>
              <Radio.Group
                onChange={handleSubscriptionTypeChange}
                value={subscriptionType}
                defaultValue={0}
              >
                <Radio value={"Herlaadkaart"}>Herlaadkaart</Radio>
                <Radio value={"Abonnement"}>Abonnement</Radio>
              </Radio.Group>
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="mt-3 mb-2">
          <div className="mb-1">
            <Typography tag={"h1"} type={"semibold"}>
              Gegevens simkaart
            </Typography>
          </div>
          <div className="col-span-3 my-1 flex">
            <ToggleSwitch
              label="eSIM"
              checked={eSim}
              onClick={() => setESim(!eSim)}
              name="geb_isAdmin"
            />
          </div>
          <div className="col-span-3 my-1 flex">
            <ToggleSwitch
              label="Budgetmanager"
              checked={budgetmanager}
              onClick={() => setBudgetmanager(!budgetmanager)}
              name="geb_isActive"
            />
          </div>
          {budgetmanager ? (
            <div>
              <ul className="mt-2">
                <li className="list-disc ml-5">
                  <Typography tag={"p"} type={"default"}>
                    U moet het intekeningsdocument onderaan uploaden.
                  </Typography>
                </li>
              </ul>
              <div className="mt-2 mb-5 flex gap-2">
                <Button
                  href={IntekeningBudgetManager}
                  download="IntekeningsdocumentBudgetmanager.pdf"
                >
                  Download intekeningsdocument budgetmanager
                </Button>
              </div>
            </div>
          ) : (
            ""
          )}

          {simcardDetails.map((sim, index) => (
            <div key={index} className="grid grid-cols-2 gap-2 mb-2">
              <div>
                <div className="mb-1">
                  <Typography tag={"h1"} type={"semibold"}>
                    Selecteer of typ simkaart
                  </Typography>
                </div>
                <SelectSimcard
                  onSelect={(value, option) =>
                    handleSimChange(value, option, index)
                  }
                  defaultValue={undefined}
                  status={"inactive"}
                  error={undefined}
                  disabled={eSim}
                />
              </div>
              <div>
                <div className="mb-1">
                  <Typography tag={"h1"} type={"semibold"}>
                    Selecteer tariefplan
                  </Typography>
                </div>
                <SelectTarif
                  onSelect={(value, option) =>
                    handleTariefplanChange(value, option, index)
                  }
                  defaultValue={undefined}
                  type={"voice"}
                />
              </div>
            </div>
          ))}

          <div className="grid grid-cols-2 gap-2 mb-3">
            <div className="mt-5">
              <div className="mb-1">
                <Typography tag={"h1"} type={"semibold"}>
                  Vestiging
                </Typography>
              </div>
              <BranchSelect
                handleSelect={handleBranchSelect}
                defaultValue={"Selecteer vestiging"}
              />
            </div>

            <div className="mt-5 mb-2">
              <div className="mb-1">
                <Typography tag={"h1"} type={"semibold"}>
                  Uitvoeringsdatum
                </Typography>
              </div>
              <DateInput
                onChange={(date) => setSelectedDate(date)}
                value={selectedDate}
                endDate={project ? project.proj_deadline : ""}
              />
            </div>
          </div>
          <div className="mb-5">
            <FileUpload
              fileTypes={["application/pdf"]}
              onUpload={handleSubmit}
            />
          </div>
        </div>
      </div>
      {loading ? <LoadingSpinner /> : ""}
    </>
  );
};

export default SimOnboardingForm;
