import React, { FC } from "react";
import Typography from "../typography/Typography";
import { useTranslation } from "react-i18next";

type Props = {
  title: string;
  subtitle?: string;
  type?: string;
};

const TextHeader: FC<Props> = ({ title, subtitle, type = "header" }) => {
  const { t } = useTranslation();
  return (
    <div className="w-full mb-2">
      <span className="text-slate-500 dark:text-slate-400 leading-none">
        {t(subtitle)}
      </span>
      <Typography tag="h2" type={type}>
        {title}
      </Typography>
    </div>
  );
};

export default TextHeader;
