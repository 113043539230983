import React, { FC, useEffect, useState } from "react";
import Typography from "../../components/typography/Typography";
import SearchTable from "../../components/inputs/search/SearchTable";
import Table from "../../components/tables/Table";
import TableItem from "../../components/tables/TableItem";
import LoadingSpinner from "../../components/buttons/LoadingSpinner";
import { UsersService } from "../../services/users/Users";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { Button } from "antd";
import { PlusCircle } from "react-feather";

type Props = {
  currentUsers: [];
};

const AddProjectUser: FC<Props> = ({ currentUsers }) => {
  const projectUsersState = useSelector(
    (state: RootState) => state.aside.data.users
  );
  const projectUsersArray = Object.values(projectUsersState).filter(
    (item) => typeof item === "object" && item.med_naam
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );

  const [availableEmployees, setAvailableEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  const userService = new UsersService();

  useEffect(() => {
    setLoading(true);
    if (companyId) {
      const fetchData = async () => {
        const users = await userService.getUsers(companyId, "");
        setAvailableEmployees(users);
        setFilteredEmployees(users);
        setLoading(false);
      };

      fetchData();
    }
  }, [companyId]);

  const filterEmployees = (searchValue: string) => {
    if (searchValue.trim() === "") {
      setFilteredEmployees(availableEmployees);
    } else {
      const filtered = availableEmployees.filter((employee) =>
        `${employee.geb_voornaam} ${employee.geb_naam}`
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      );
      setFilteredEmployees(filtered);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    filterEmployees(event.target.value);
  };

  const onRemoveUser = async (user: {}) => {
    console.log("Remove user clicked");
  };

  const onAddUser = (employee: {}) => {
    console.log("Add user clicked", employee);
  };

  const tableHeaders: object = ["Naam", "E-mail", "Acties"];

  return (
    <div>
      {projectUsersArray.length > 0 && (
        <>
          <Typography tag="h3" type="header">
            Huidige gebruikers
          </Typography>
          <div className="flex flex-col gap-2 my-3">
            {projectUsersArray.map((user, index) => (
              <div key={index} className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="relative inline-flex items-center justify-center w-10 h-10 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600 border-2 border-white dark:border-slate-800">
                    <span className="font-medium text-gray-600 dark:text-gray-300">
                      {user.med_naam.charAt(0)}
                    </span>
                  </div>
                  <Typography tag="p" type="default">
                    {user.med_naam}
                  </Typography>
                </div>
                <Button onClick={() => onRemoveUser(user)} danger>
                  Remove
                </Button>
              </div>
            ))}
          </div>
        </>
      )}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Typography tag="h3" type="header">
            Beschikbare gebruikers
          </Typography>
          <div className="overflow-y-hidden border dark:border-gray-700 rounded-lg mt-3">
            <SearchTable
              handleChange={handleSearchChange}
              placeholder="Gebruiker zoeken..."
              value={searchQuery}
            />
            <Table header={tableHeaders}>
              {filteredEmployees.length > 0 ? (
                filteredEmployees.map((employee) => (
                  <tr key={employee.geb_id}>
                    <TableItem type="tiny">
                      {employee.geb_voornaam} {employee.geb_naam}
                    </TableItem>
                    <TableItem type="tiny">{employee.email}</TableItem>
                    <TableItem type="tiny">
                      <button
                        onClick={() => onAddUser(employee)}
                        className="text-green-500 flex items-center"
                      >
                        <PlusCircle size={18} />
                      </button>
                    </TableItem>
                  </tr>
                ))
              ) : (
                <tr>
                  <TableItem type="tiny">Geen gebruikers gevonden</TableItem>
                </tr>
              )}
            </Table>
          </div>
        </>
      )}
    </div>
  );
};

export default AddProjectUser;
