import React, { ChangeEvent, FC, useEffect, useState } from "react";
import SelectBox from "../inputs/selectbox/SelectBox";
import { UsersService } from "../../services/users/Users";
import { useSelector } from "react-redux";
import { RootState } from "../../state/store";

type Props = {
  handleSelect: (value: string, option: any) => void;
  defaultValue: string;
  loading: boolean;
};

const UserSelect: FC<Props> = ({ handleSelect, defaultValue, loading }) => {
  const [users, setUsers] = useState<
    { label: string; value: string; searchLabel: string }[]
  >([]);
  const companyId: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );
  const service = new UsersService();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const data = await service.getUserNames(companyId);
      const formattedUsers = data.map((user) => ({
        label: user.label,
        value: user.value,
        searchLabel: user.label,
      }));
      setUsers(formattedUsers);
    } catch (error) {
      console.error("Error fetching usernames:", error);
    }
  };

  return (
    <SelectBox
      onChange={handleSelect}
      defaultValue={defaultValue}
      options={users}
      loading={loading}
      search={true}
      notFoundText="No users found"
    />
  );
};

export default UserSelect;
