import React, { FC } from "react";
import Tabs from "../../../components/tabs/Tabs";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import TextHeader from "../../../components/headers/TextHeader";
import GroupMembersContainer from "./GroupMembersContainer";
import EditGroupForm from "../../../components/forms/groups/EditGroupForm";

const GroupDetailContainer: FC = () => {
  const groupstate = useSelector((state: RootState) => state.aside.data);
  const tabs = [
    {
      nav: "Bewerken",
      component: [<EditGroupForm group={groupstate.group} />],
    },
    {
      nav: "Gebruikers",
      component: [<GroupMembersContainer group={groupstate.group} />],
    },
  ];
  return (
    <div>
      <TextHeader title={groupstate.group.bedGr_naam} subtitle={"Groep"} />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default GroupDetailContainer;
