import React, { FC } from "react";
import Typography from "../../../components/typography/Typography";
import { HardDrive } from "react-feather";
import { Progress } from "antd";

const ProductStorage = ({ volumes }) => {
  return (
    <div>
      {/* <Typography tag={"p"} type={"semibold"}>
        Opslag
      </Typography> */}
      {volumes?.map((volume: any) => {
        if (volume.capacity > 0) {
          return (
            <div className="flex items-center gap-4 mt-3">
              <HardDrive />
              <div className="w-1/2">
                <Typography tag={"p"} type={"default"}>
                  {volume.name}/{volume.label}
                </Typography>
                <Typography tag={"p"} type={"default"}>
                  {Math.round((volume.freeSpace / 1000000000) * 100) / 100}
                  GB van&nbsp;
                  {Math.round((volume.capacity / 1000000000) * 100) / 100}
                  GB beschikbaar
                </Typography>
                <Progress
                  percent={Math.round(
                    ((volume.capacity - volume.freeSpace) / volume.capacity) *
                      100
                  )}
                  strokeColor={
                    (volume.capacity - volume.freeSpace) / volume.capacity > 0.8
                      ? "#ef4444"
                      : ""
                  }
                />
              </div>
            </div>
          );
        } else {
          return null; // Return null if the condition is not met
        }
      })}
    </div>
  );
};

export default ProductStorage;
