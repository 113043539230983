export enum UserRoles {
  ADMIN = "admin",
  USER = "user",
}

export const hasRole = (
  roles: { role: string }[],
  roleToCheck: UserRoles
): boolean => {
  return roles.some((role) => role.role === roleToCheck);
};
