import React, { ChangeEvent, FC } from "react";
import { useTranslation } from "react-i18next";

interface InputFieldProps {
  label?: string;
  info?: string;
  value?: string;
  name: string;
  style?: keyof typeof styles;
  placeholder: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
}

const styles = {
  default:
    "border border-gray-300 dark:border-slate-600 text-sm rounded-lg block w-full p-2.5 bg-transparent border focus:outline-blue-700",
  transparant:
    "bg-transparent mb-2 border-b border-0 py-3 w-full rounded-none px-6 dark:border-gray-700",
  buttonInline:
    "border border-gray-300 dark:border-slate-600 text-sm rounded-lg block w-full p-2.5 bg-transparent border focus:outline-blue-700 px-4 pl-4 pr-20",
};

const Textarea: FC<InputFieldProps> = ({
  label,
  name,
  style = "default",
  info,
  placeholder,
  onChange,
  error,
  value,
}) => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <span className="text-sm block font-normal">{info}</span>

      {label ? (
        <label
          htmlFor={name}
          className="block mb-2 text-sm font-medium text-slate-800 dark:text-slate-300"
        >
          {t(label)}
        </label>
      ) : (
        ""
      )}
      <textarea
        name={name}
        value={value}
        placeholder={t(placeholder)}
        onChange={onChange}
        className={` ${styles[style]} ${
          error ? "border-red-500" : "border-gray-300"
        } `}
      />
    </div>
  );
};

export default Textarea;
