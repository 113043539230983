import React, { FC } from "react";
import Typography from "../typography/Typography";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
};

const StatusTag: FC<Props> = ({ label }) => {
  const { t } = useTranslation();

  const renderStyle = (style: string) => {
    switch (style) {
      case "Actief":
        return "bg-green-100 text-green-700 rounded-full";
      case "Tijdelijk buiten dienst":
        return "bg-red-100 text-red-700 rounded-full";
      default:
        return "bg-orange-50 text-orange-400 rounded-full";
    }
  };

  return (
    <span
      className={`${renderStyle(label)} text-xs font-bold uppercase px-3 py-1`}
    >
      {t(label)}
    </span>
  );
};

export default StatusTag;
