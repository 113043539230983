import React, { FC, ReactNode, useEffect } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  children: ReactNode;
  header: object;
  fixedColumns?: { key: string; position: "left" | "right" }[];
};

const Table: FC<Props> = ({ children, header, fixedColumns }) => {
  const { t } = useTranslation();

  return (
    <div className="table-container overflow-x-auto">
      <table className="w-full min-w-max max-w-full table-auto text-left divide-y divide-gray-200 dark:divide-slate-700">
        {Object.keys(header).length > 0 ? (
          <thead>
            <tr>
              {Object.values(header).map((name) => {
                const isFixed = fixedColumns?.find((col) => col.key === name);
                const stickyClass = isFixed
                  ? `sticky ${isFixed.position}-0 z-10 bg-white dark:bg-gray-900`
                  : "";
                return (
                  <th
                    key={name}
                    className={`pl-6 pr-3 py-3 text-start text-xs font-medium uppercase ${stickyClass}`}
                  >
                    {t(name)}
                  </th>
                );
              })}
            </tr>
          </thead>
        ) : (
          ""
        )}
        <tbody className="divide-y divide-gray-100 dark:divide-gray-800">
          {children}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
