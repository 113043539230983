import { PayloadAction, createSlice } from "@reduxjs/toolkit";
interface Company {
    id: number;
    companyName: string;
}

interface CompaniesState {
    companies: Company[];
    selectedCompany?: Company;
}

const initialState: CompaniesState = {
    companies: [],
    selectedCompany: undefined
};

const companiesSlice = createSlice({
    name: "companies",
    initialState,
    reducers: {
        setCompanies: (state, action: PayloadAction<Company[]>) => {
            state.companies = action.payload;
        },
        clearCopanies: (state) => {
            state.companies = [];
            state.selectedCompany = undefined;
        },
        setSelectedCompany: (state, action: PayloadAction<Company>) => {
            state.selectedCompany = action.payload;
        }
    },
});

export const { setCompanies, clearCopanies, setSelectedCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
