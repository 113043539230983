import apiClient from "../../../api-client";
import { AppendSearchParams } from "../../utils/AppendSearchParams";

interface Simcard {
  sim_id: number;
  sim_unique_nr: string;
  sim_type: string;
  sim_status: string;
  [x: string]: any;
}

interface getSimcard {
  simId: number;
  search?: string;
}

export class SimcardService {
  // NOT USED ANYWHERE
  public async getSimcard(simId: number): Promise<Simcard[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/telecom/sim/${simId}`
    // );
    // return await response.json();
  }

  public async updateSimcard(simId: number, value: object, companyId: number) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/${simId}`,
        value
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating simcard: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async updateOrCreateProximusSim(simData: object, companyId: number) {
    try {
      const response = await apiClient.patch(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/sims/updateorcreate`,
        simData
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating or creating simcard: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getSimcards(
    companyId: number,
    search?: string,
    status?: string
  ): Promise<Simcard[]> {
    try {
      const queryParams = new URLSearchParams();
      if (search) queryParams.append("search", search);
      if (status) queryParams.append("status", status);

      const url = `${
        process.env.GATSBY_SSO_BACKEND_API_URL
      }/company/${companyId}/sims${
        queryParams.toString() ? `?${queryParams.toString()}` : ""
      }`;

      const response = await apiClient.get(url);
      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all simcards: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  // NOT USED ANYWHERE
  public async getTariefplannen(): Promise<Simcard[]> {
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/mobileplans`
    // );
    // return await response.json();
  }

  // Change call to new backend
  public async getBruto(props: string[]) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("accept", "application/json");

    const raw = {
      unit: "bruto",
      ...props,
      limit: 36,
      offset: 0,
    };

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(raw),
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.GATSBY_PORTAL_API_URL}/api/sim/metrics/bruto`,
      requestOptions
    );

    return await response.json();
  }

  // Change call to new backend
  public async getUsage(
    cdbi: string,
    year: number,
    months: object,
    props?: string[]
  ) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      unit: "usage",
      years: [year],
      months: months,
      ...props,
      limit: 36,
      offset: 0,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.GATSBY_PORTAL_API_URL}/api/sim/metrics/bruto`,
      requestOptions
    );
    return await response.json();
  }

  // Change call to new backend
  public async getUsagePerUser(props) {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify({
      unit: "bruto",
      // groupBy: ["phone_number"],
      ...props,
      limit: 12,
      offset: 0,
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.GATSBY_PORTAL_API_URL}/api/sim/metrics/bruto`,
      requestOptions
    );
    return await response.json();
  }

  // NOT USED ANYWHERE
  public async removeSimcard(simId: number) {
    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    // const raw = JSON.stringify({
    //   simId: simId,
    // });
    // const requestOptions: object = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: raw,
    //   redirect: "follow",
    // };
    // const response = await fetch(
    //   `${process.env.GATSBY_PORTAL_API_URL}/api/simcard/danger/remove`,
    //   requestOptions
    // );
    // return await response.json();
  }
}
