import "./src/styles/global.css";
import "./i18";

import React from "react";

import Layout from "./src/components/layouts/Layout";

const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

export {
    wrapPageElement
}