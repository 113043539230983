import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import SelectBox from "../inputs/selectbox/SelectBox";
import { RootState } from "../../state/store";
import { UsersService } from "../../services/users/Users";
import { navigate } from "gatsby";
import { toggleSearchBar } from "../../state/search/searchBarSlice";
import { Search, User } from "react-feather";
import { useTranslation } from "react-i18next";

const SearchBar = () => {
  const { t } = useTranslation();
  const service = new UsersService();
  const isVisible = useSelector(
    (state: RootState) => state.searchBar.isVisible
  );
  const companyId: number = useSelector(
    (state: RootState) => state.auth.roles[0]?.companyId
  );
  const dispatch = useDispatch();
  const [optionsLoading, setOptionsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const debounceTimeout = useRef<number | undefined>(undefined);

  const searchBarRef = useRef<HTMLDivElement>(null);
  const selectBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      const dropdownElement = document.querySelector(
        ".ant-select-dropdown"
      ) as HTMLElement;

      if (
        searchBarRef.current &&
        !searchBarRef.current.contains(event.target as Node) &&
        dropdownElement &&
        !dropdownElement.contains(event.target as Node)
      ) {
        dispatch(toggleSearchBar());
      }
    }

    if (isVisible) {
      document.addEventListener("mousedown", handleClickOutside);
      setTimeout(() => {
        (selectBoxRef.current as any)?.focus();
      }, 100);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isVisible, dispatch]);

  const fetchOptions = async (term: string) => {
    setOptionsLoading(true);

    const trimmedTerm = term.trim();

    if (trimmedTerm.length >= 3) {
      const fetchedOptions = await service.getUserNames(companyId, trimmedTerm);
      const processedOptions = fetchedOptions.map((option) => ({
        label: (
          <div className="flex justify-between items-center">
            <p>{option.label}</p>
            <User size={18} />
          </div>
        ),
        value: option.value,
        searchLabel: option.label,
      }));
      setOptions(processedOptions);
    } else {
      setOptions([]);
    }
    setOptionsLoading(false);
  };

  const handleSearch = (term: string) => {
    clearTimeout(debounceTimeout.current);

    debounceTimeout.current = window.setTimeout(() => {
      fetchOptions(term);
    }, 500);
  };

  const handleSelect = async (value: string, label: string) => {
    if (value) {
      setTimeout(() => {
        navigate(`/user/dashboard/overview?user=${value}`);
        dispatch(toggleSearchBar());
      }, 100);
    }
  };

  return isVisible ? (
    <>
      <div
        className="fixed inset-0 bg-black bg-opacity-50 z-40"
        onClick={() => dispatch(toggleSearchBar())}
      />
      <div
        className="fixed top-0 md:top-10 left-1/2 transform -translate-x-1/2 z-[70] w-full md:w-96 bg-white dark:bg-slate-700 p-4 rounded-b-lg md:rounded-lg shadow-lg"
        ref={searchBarRef}
      >
        <SelectBox
          search={true}
          options={options}
          defaultValue={t("Typ een naam om te zoeken")}
          onChange={handleSelect}
          onSearch={handleSearch}
          loading={optionsLoading}
          notFoundText={t("Geen resultaten gevonden")}
          props={{ ref: selectBoxRef }}
          suffixIcon={
            <>
              <span>Ctrl+K</span>
              <Search size={14} />
            </>
          }
        />
      </div>
    </>
  ) : null;
};

export default SearchBar;
