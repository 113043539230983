import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../state/store";
import {
  increaseItemCount,
  decreaseItemCount,
  removeItemFromCart,
} from "../../../state/shop/shopSlice";
import FetchImage from "../../../components/images/FetchImage";
import Typography from "../../../components/typography/Typography";
import { Link } from "gatsby";
import { CornerDownRight } from "react-feather";
import {
  addCyodCoupon,
  addCyodUsedCoupon,
  removeCyodProduct,
} from "../../../state/shop/cyodSlice";
import Tag from "../../../components/tags/Tag";
import { CouponService } from "../../../services/coupons/CouponService";
import CalculateTotalPrice from "../../../utils/cart/CalculateTotalPrice";
import CurrencyFormatter from "../../../utils/text/CurrencyFormatter";
import { useTranslation } from "react-i18next";

const ShoppingCartPage = () => {
  const { t } = useTranslation();
  const cart = useSelector((state: RootState) => state.cart);
  const cyod = useSelector((state: RootState) => state.cyod);
  const dispatch = useDispatch();

  const couponService = new CouponService();

  useEffect(() => {
    if (cyod.selectedProducts && cyod.coupon === 0 && cyod.usedCoupon === 0) {
      fetchCoupon();
    }
  }, []);

  const handleRemoveItem = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(removeItemFromCart({ id }));
  };

  const handleRemoveCyod = (
    productId: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(removeCyodProduct(productId));
  };

  const handleIncreaseItemCount = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(increaseItemCount({ id }));
  };

  const handleDecreaseItemCount = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    dispatch(decreaseItemCount({ id }));
  };

  const fetchCoupon = async () => {
    const couponValue = await couponService.getCoupon();
    dispatch(addCyodCoupon(couponValue.cou_bedrag));
  };

  const addCoupon = () => {
    const totalPrice = CalculateTotalPrice(cart, cyod);
    if (cyod.coupon > totalPrice) {
      dispatch(addCyodCoupon(cyod.coupon - totalPrice));
      dispatch(addCyodUsedCoupon(totalPrice));
    } else {
      dispatch(addCyodUsedCoupon(cyod.coupon));
      dispatch(addCyodCoupon(0));
    }
  };

  const removeCoupon = () => {
    dispatch(addCyodCoupon(cyod.coupon + cyod.usedCoupon));
    dispatch(addCyodUsedCoupon(0));
  };

  return (
    <>
      {cart.totalItems > 0 || Object.keys(cyod.selectedProducts).length > 0 ? (
        <div>
          {Object.keys(cyod.selectedProducts).length > 0 && (
            <>
              {Object.keys(cyod.selectedProducts).map((productId) => {
                const product = cyod.selectedProducts[productId].product;
                const accessories =
                  cyod.selectedProducts[productId].accessories;
                return (
                  <div key={productId}>
                    <div className="text-center md:text-left grid grid-cols-2 md:grid-cols-5 items-center border-b dark:border-gray-700 py-4">
                      <div className="mx-auto col-span-2 md:col-span-1">
                        <FetchImage productId={productId} style={"cart"} />
                      </div>
                      <div className="col-span-3 flex flex-col">
                        <Typography tag={"h1"} type={"semibold"}>
                          {product.name}
                        </Typography>
                        <div className="flex items-center justify-center md:justify-start mt-5">
                          <div>
                            <input
                              value={product.quantity}
                              type="number"
                              id={`quantity_${productId}`}
                              className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="1"
                              required
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-span-2 md:col-span-1 mx-auto text-center mt-5 md:mt-0">
                        <Typography tag={"h1"} type={"semibold"}>
                          <CurrencyFormatter
                            tag="excl. BTW"
                            price={product.price / 1.21}
                          />
                        </Typography>
                        <button
                          onClick={(event) =>
                            handleRemoveCyod(productId, event)
                          }
                          className="font-medium text-red-600 dark:text-red-500 hover:underline mt-5"
                        >
                          Remove
                        </button>
                      </div>
                    </div>

                    {accessories.map((accessory) => (
                      <div
                        key={accessory.accessory.id}
                        className="grid grid-cols-2 md:grid-cols-5 items-center border-b dark:border-gray-700 py-4"
                      >
                        <div className="mx-auto hidden md:block">
                          <CornerDownRight />
                        </div>
                        <div className="max-w-32 mx-auto col-span-2 md:col-span-1">
                          <FetchImage
                            productId={accessory.accessory.id}
                            style={"cart"}
                          />
                        </div>
                        <div className="col-span-2 text-center md:text-left">
                          <Typography tag={"h1"} type={"semibold"}>
                            {accessory.accessory.name}
                          </Typography>
                          <div className="flex items-center justify-center md:justify-start mt-5">
                            <div>
                              <input
                                value={accessory.accessory.quantity}
                                type="number"
                                id={`quantity_${accessory.accessory.id}`}
                                className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                placeholder="1"
                                required
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-span-2 md:col-span-1 mx-auto mt-5 md:mt-0">
                          <Typography tag={"h1"} type={"semibold"}>
                            <CurrencyFormatter
                              tag="excl. BTW"
                              price={accessory.accessory.sale_price / 1.21}
                            />
                          </Typography>
                        </div>
                      </div>
                    ))}
                  </div>
                );
              })}
              {/* Coupon */}
              {cyod.coupon > 0 || cyod.usedCoupon > 0 ? (
                <div className="grid grid-cols-2 md:grid-cols-5 items-center border-b dark:border-gray-700 py-4 text-center">
                  <div className="mx-auto hidden md:block">
                    <CornerDownRight />
                  </div>
                  <div className="col-span-2 md:col-span-1 mb-3 md:mb-0">
                    <Typography tag={"h1"} type={"semibold"}>
                      CYOD coupon
                    </Typography>
                  </div>
                  <div className="col-span-1 md:col-span-2">
                    <Typography tag={"p"} type={"default"}>
                      Resterend bedrag:
                    </Typography>
                    <span className="text-green-500">
                      <CurrencyFormatter tag="excl. BTW" price={cyod.coupon} />
                    </span>
                  </div>
                  <div className="col-span-1">
                    {cyod.usedCoupon > 0 ? (
                      <button onClick={removeCoupon}>
                        <Tag style={"danger"} label={"Verwijderen"} />
                      </button>
                    ) : (
                      <button onClick={addCoupon}>
                        <Tag style={"success"} label={"Toevoegen"} />
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </>
          )}
          {/* Products */}
          {Object.keys(cart.items).map((itemId) => {
            const item = cart.items[itemId];
            return (
              <div
                key={itemId}
                className="text-center md:text-left grid grid-cols-2 md:grid-cols-5 items-center border-b dark:border-gray-700 py-4"
              >
                <div className="mx-auto col-span-2 md:col-span-1">
                  <FetchImage productId={itemId} style={"cart"} />
                </div>
                <div className="col-span-3">
                  <Typography tag={"h1"} type={"semibold"}>
                    {item.name}
                  </Typography>
                  <div className="flex items-center justify-center md:justify-start mt-5">
                    {item.quantity > 1 ? (
                      <button
                        onClick={(event) =>
                          handleDecreaseItemCount(itemId, event)
                        }
                        className="inline-flex items-center justify-center p-1 me-3 text-sm font-medium h-6 w-6 text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                        type="button"
                      >
                        <span className="sr-only">Quantity button</span>
                        <svg
                          className="w-3 h-3"
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 18 2"
                        >
                          <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M1 1h16"
                          />
                        </svg>
                      </button>
                    ) : (
                      ""
                    )}
                    <div>
                      <input
                        value={item.quantity}
                        type="number"
                        id={`quantity_${itemId}`}
                        className="bg-gray-50 w-14 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-2.5 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="1"
                        required
                        readOnly
                      />
                    </div>
                    <button
                      onClick={(event) =>
                        handleIncreaseItemCount(itemId, event)
                      }
                      className="inline-flex items-center justify-center h-6 w-6 p-1 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      type="button"
                    >
                      <span className="sr-only">Quantity button</span>
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 18 18"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M9 1v16M1 9h16"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="col-span-2 md:col-span-1 mx-auto text-center mt-5 md:mt-0">
                  <Typography tag={"h1"} type={"semibold"}>
                    <CurrencyFormatter
                      tag="excl. BTW"
                      price={item.price * item.quantity}
                    />
                  </Typography>
                  <button
                    onClick={(event) => handleRemoveItem(itemId, event)}
                    className="font-medium text-red-600 dark:text-red-500 hover:underline mt-5"
                  >
                    {t("Remove")}
                  </button>
                </div>
              </div>
            );
          })}
          <div className="mt-1 opacity-75">
            <Typography tag={"p"} type={"label"}>
              * De weergegeven prijzen zijn exclusief btw
            </Typography>
          </div>
        </div>
      ) : (
        <div className="text-left">
          <Typography tag={"h1"} type={"subtitle"}>
            Uw winkelwagen is leeg
          </Typography>
          <Link to="/shop/categories/overview">{t("Verder winkelen")}</Link>
        </div>
      )}
    </>
  );
};

export default ShoppingCartPage;
