import React from "react";
import TicketForm from "../tickets/TicketForm";
import TextHeader from "../../components/headers/TextHeader";

const CreateTicketContainer = () => {
  return (
    <div>
      <TextHeader title={"Ticket aanmaken"} />
      <TicketForm />
    </div>
  );
};

export default CreateTicketContainer;
