import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface GroupState {
  groups: object[];
}

const initialState: GroupState = {
  groups: [],
};

const groupSlice = createSlice({
  name: "group",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<object[]>) => {
      state.groups = action.payload;
    },
    updateGroup: (state, action) => {
      const { id, updatedData } = action.payload;
      const existingGroup = state.groups.find((group) => group.bedGr_id === id);
      if (existingGroup) {
        Object.assign(existingGroup, { ...existingGroup, ...updatedData });
      }
    },
    createGroup: (state, action: PayloadAction<object>) => {
      state.groups.push(action.payload);
    },
    deleteGroup: (state, action: PayloadAction<number>) => {
      state.groups = state.groups.filter(
        (group) => group.bedGr_id !== action.payload
      );
    },
  },
});

export const { setGroups, updateGroup, createGroup, deleteGroup } =
  groupSlice.actions;

export default groupSlice.reducer;
