import { Tooltip } from "antd";
import React, { FC } from "react";

type Props = {
  image: string;
  name: string;
};

const EmployeeThumb: FC<Props> = ({ image, name }) => {
  return (
    <span>
      {image ? (
        <Tooltip title={name}>
          <img
            src={image}
            alt={name}
            className={`rounded-full ring-2 ring-blue-700 h-8 w-8 hover:scale-125 transition-all`}
          />
        </Tooltip>
      ) : (
        <div className="rounded-full ring-2 p-0.5 ring-blue-700 h-8 w-8 hover:scale-125 transition-all flex justify-center items-center">
          <p className="text-blue-700 font-semibold text-xs">CMC</p>
        </div>
      )}
    </span>
  );
};

export default EmployeeThumb;
