import React, { FC, useEffect, useState } from "react";
import Typography from "../../typography/Typography";
import Table from "../../tables/Table";
import TableItem from "../../tables/TableItem";
import { Check, X } from "react-feather";
import Checkmark from "../../tags/Checkmark";
import { AssetsService } from "../../../services/assets/Assets";
import LoadingSpinner from "../../buttons/LoadingSpinner";
import TextHeader from "../../headers/TextHeader";
import SearchTable from "../../inputs/search/SearchTable";
import FormButton from "../../buttons/FormButton";
import { Link } from "gatsby";
import { setAside } from "../../../state/component/AsideSlice";
import { useDispatch, useSelector } from "react-redux";
import { ProjectsService } from "../../../services/project/Projects";
import { DatePicker } from "antd";
import { setCurrentProject } from "../../../state/project/ProjectSlice";
import { RootState } from "../../../state/store";
import dayjs from "dayjs";
import { addToast } from "../../../state/toast/ToastSlice";
import { useTranslation } from "react-i18next";

const HardwareOnboarding: FC = () => {
  const { t } = useTranslation();
  const [assets, setAssets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedHardware, setSelectedHardware] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const task = useSelector((state: RootState) => state.aside.data.task);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new AssetsService();
  const projectService = new ProjectsService();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAssets();
  }, []);

  const fetchAssets = async () => {
    setLoading(true);
    try {
      const data = await service.getAssets(companyId);

      const filtered = data.filter((asset) => asset.ordSer_user_id === null);

      setAssets(filtered);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assets:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleToggleSelection = (id: string) => {
    setSelectedHardware((prevState) => {
      const selectedAsset = assets.find((asset) => asset.ordSer_id === id);
      if (prevState[id]) {
        const { [id]: removedItem, ...rest } = prevState;
        return rest;
      } else {
        return {
          ...prevState,
          [id]: {
            ...selectedAsset,
            ordPro_naam: selectedAsset.ordPro_naam,
            ordSer_serial: selectedAsset.ordSer_serial,
          },
        };
      }
    });
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  const filteredAssets = assets.filter((asset) => {
    return (
      asset.ordPro_naam.toLowerCase().includes(searchQuery.toLowerCase()) ||
      asset?.ordSer_serial?.toLowerCase().includes(searchQuery.toLowerCase())
    );
  });

  const handleSubmit = async () => {
    dispatch(
      setAside({
        active: 0,
      })
    );

    try {
      const project = await projectService.finishAssignment(
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(
        addToast({
          description: "De taak is succesvol afgerond.",
          position: "bottomRight",
          style: "success",
        })
      );

      await service.updateAssetsUser(
        Object.keys(selectedHardware),
        task.metadata[0].value
      );

      dispatch(setCurrentProject(project));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  const tableHeaders: object = [
    "Product",
    "Serienummer",
    "Remote managed",
    "Toevoegen",
  ];

  return (
    <div className="overflow-hidden">
      <TextHeader title={"Hardware"} />
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          <Typography tag={"h1"} type={"semibold"}>
            Bestaande hardware
          </Typography>
          <SearchTable
            handleChange={handleSearchChange}
            placeholder="Zoek hardware..."
            value={searchQuery}
          />
          <Table header={tableHeaders}>
            {filteredAssets.map((asset: any) => (
              <tr key={asset.ordSer_id}>
                <TableItem>{asset.ordPro_naam}</TableItem>
                <TableItem>{asset.ordSer_serial}</TableItem>
                <TableItem>
                  <Checkmark check={asset.device_id ? 1 : 0} />
                </TableItem>
                <TableItem>
                  <button
                    className={
                      selectedHardware[asset.ordSer_id]
                        ? "text-red-500"
                        : "text-green-500"
                    }
                    onClick={() => handleToggleSelection(asset.ordSer_id)}
                  >
                    {selectedHardware[asset.ordSer_id] ? (
                      <X size={18} />
                    ) : (
                      <Check size={18} />
                    )}
                  </button>
                </TableItem>
              </tr>
            ))}
          </Table>
          <div className="mt-5">
            <Typography tag={"h1"} type={"semibold"}>
              Nieuwe hardware
            </Typography>
            <Typography tag={"p"} type={"default"}>
              U kan nieuwe hardware toevoegen door middel van een 'bulk'
              bestelling te doen in de webshop.
            </Typography>
            <Typography tag={"p"} type={"label"}>
              De nieuwe hardware wordt beschikbaar om toe te wijzen wanneer er
              een serienummer aan toegevoegd is.
            </Typography>
            <div className="mt-2">
              <Link
                to="/shop/categories/overview"
                className="text-blue-500 hover:text-blue-700"
              >
                {t("Naar webshop")}
              </Link>
            </div>
          </div>
          {/* <div className="mt-5 mb-2">
            <div className="mb-1 w-1/2">
              <Typography tag={"h1"} type={"semibold"}>
                Uitvoeringsdatum
              </Typography>
            </div>
            <DatePicker
              format="DD/MM/YY"
              placeholder="Datum"
              value={selectedDate}
              defaultValue={selectedDate}
              style={{ display: "block" }}
              required={true}
              onChange={(date) => setSelectedDate(date)}
            />
          </div> */}
          <div className="mt-3">
            <FormButton onClick={handleSubmit} text={"Selectie bevestigen"} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HardwareOnboarding;
