import { FC } from "react";
import TextHeader from "../../../components/headers/TextHeader";
import React from "react";
import BranchForm from "../../../components/forms/branches/BranchForm";

const BranchCreateContainer: FC = () => {
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Vestiging" />
      <BranchForm branch={{}}></BranchForm>
    </div>
  );
};

export default BranchCreateContainer;
