import React, { FC, ReactNode } from "react";
import LoadingSpinner from "./LoadingSpinner";
import { useTranslation } from "react-i18next";

type Props = {
  text: string;
  onClick: any;
  icon?: ReactNode;
  loading?: boolean;
  style?: keyof typeof styles;
  color?: keyof typeof colors;
  spinner?: keyof typeof styles;
};

const styles = {
  default: "bg-blue-700 py-2 px-4 text-white mt-3 hover:bg-blue-800",
  danger: "bg-red-100 text-red-700 py-1 px-4",
  dangernbg: "text-red-700 py-1 px-4",
  small: "text-sm py-1 px-3",
  noMargin: "bg-blue-700 py-2 px-4 text-white hover:bg-blue-800",
};

const colors = {
  default: "bg-blue-700 hover:bg-blue-800 text-white",
  transparent: "border-2 border-blue-700 hover:border-blue-800",
};

const spinner = {
  default: "#1D4ED8",
  danger: "#b91c1c",
};

const Cta: FC<Props> = ({
  text,
  onClick,
  icon,
  style = "default",
  loading = false,
  color = false,
}) => {
  const handleClick = (event) => {
    event.preventDefault(); // Prevent default button behavior (e.g., form submission)
    onClick(event); // Call the onClick handler provided by the parent component
  };
  const { t } = useTranslation();
  return (
    <button
      onClick={handleClick}
      className={`rounded-xl flex items-center ${styles[style]} ${colors[color]}`}
    >
      {icon && !loading ? (
        <>
          <span className="mr-2">{icon}</span>
          <span>{t(text)}</span>
        </>
      ) : loading ? (
        <>
          <LoadingSpinner stroke={spinner[style]} />
          <span>{t(text)}</span>
        </>
      ) : (
        <span>{t(text)}</span>
      )}
    </button>
  );
};

export default Cta;
