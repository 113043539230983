import React, { FC, useState, useEffect, ChangeEvent, useReducer } from "react";
import { RootState } from "../../../state/store";
import { useDispatch, useSelector } from "react-redux";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import { SimcardService } from "../../../services/simcards/Simcards";

import SimUserUsage from "./SimUserUsage";
import Tabs from "../../../components/tabs/Tabs";
import Box from "../../../components/boxes/Box";
import Typography from "../../../components/typography/Typography";
import StatusTag from "../../../components/tags/StatusTag";
import SimUsagePerUser from "./SimUsagePerUser";
import RatePlanInfo from "../../../components/typography/sim/RatePlanInfo";
import SimActions from "./SimActions";
import InputField from "../../../components/inputs/text/InputField";
import SimTickets from "./SimTickets";
import UserSelect from "../../../components/users/UserSelect";
import { addToast } from "../../../state/toast/ToastSlice";
import { XCircle } from "react-feather";
import FormField from "../../../components/inputs/text/FormField";
import Cta from "../../../components/buttons/Cta";
import { useTranslation } from "react-i18next";

const SimDetailContainer = () => {
  const { t } = useTranslation();
  const simState = useSelector((state: RootState) => state.aside.data);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const service = new SimcardService();
  const simcardService = new SimcardService();
  const [loading, setLoading] = useState(true);
  const [sim, setSim] = useState([""]);
  const [force, forceUpdate] = useReducer((x) => x + 1, 0);
  const dispatch = useDispatch();

  const tabs = [
    {
      nav: t("Acties"),
      component: [<SimActions />],
    },
    {
      nav: t("Tickets"),
      component: [<SimTickets simId={simState.sim.sim_unique_nr} />],
    },
    {
      nav: "Maandverbruik",
      component: [<SimUserUsage phoneNumbers={[simState.sim.sim_gsm_nr]} />],
    },
    {
      nav: "Verbruik 12m",
      component: [
        <SimUsagePerUser
          key={1}
          props={{
            phoneNumbers: [simState.sim.sim_gsm_nr],
          }}
        />,
      ],
    },
  ];

  useEffect(() => {
    setSim(simState.sim);
    setLoading(false);
  }, [simState.sim]);

  const changeInput = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const { name, value } = event.target;
    setSim((prev) => ({
      ...prev,
      [name]: value,
    }));
    changeSimState({ [name]: value });
    await service.updateSimcard(
      simState.sim.sim_id,
      {
        [name]: value,
      },
      companyId
    );
  };

  const changeSimState = (prop: object) => {
    dispatch(setSimcard({ props: prop, key: simState.sim.key }));
  };

  const handleSelect = async (value: string, label: string) => {
    simcardService.updateOrCreateProximusSim(
      {
        sim_unique_nr: simState.sim.sim_unique_nr,
        sim_user_id: value,
      },
      companyId
    );

    dispatch(
      setSimcard({
        props: { sim_user_id: value, sim_user: label.label },
        key: simState.sim.key,
      })
    );

    dispatch(
      addToast({
        description: "De gebruiker is gewijzigd.",
        position: "bottomRight",
        style: "success",
      })
    );
  };

  const handleRemoveUser = async () => {
    setLoading(true);
    try {
      simcardService.updateOrCreateProximusSim(
        {
          sim_unique_nr: simState.sim.sim_unique_nr,
          sim_user_id: null,
        },
        companyId
      );

      dispatch(
        setSimcard({
          props: { sim_user_id: null, sim_user: null },
          key: simState.sim.key,
        })
      );

      forceUpdate();

      dispatch(
        addToast({
          description: "Gebruiker verwijderd van de simkaart.",
          position: "bottomRight",
          style: "success",
        })
      );
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error removing user from sim:", error);
    }
  };

  return (
    <div key={simState.sim.sim_id}>
      <Box gridSize="grid-5" type="borderedTiny" size="noSize">
        <div className="px-5 py-4 col-span-1">
          <Typography tag="span" type="label">
            Simkaart
          </Typography>
          <div className="block">
            <Typography tag="span" type="label">
              {simState.sim.sim_unique_nr}
            </Typography>
          </div>
        </div>

        <div className="px-5 py-4 col-span-2">
          <Typography tag="span" type="label">
            Telefoonnummer
          </Typography>
          <div className="block">
            <Typography tag="span" type="label">
              {simState.sim.sim_gsm_nr}
            </Typography>
          </div>
        </div>

        <div className="px-5 py-4 col-span-2">
          <StatusTag label={simState.sim.sim_status} />
        </div>
      </Box>

      <div className="mb-3"></div>

      <Box gridSize="grid-1" type="borderedTiny" size="noSize">
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Gebruiker
            </Typography>
          </div>
          <div className="col-span-2">
            {/* <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_user"
                value={simState.sim.sim_user}
                defaultValue={simState.sim.sim_user || ""}
                placeholder={t("Gebruiker toekennen")}
              />
            </Typography> */}
            <UserSelect
              key={force}
              handleSelect={handleSelect}
              defaultValue={simState.sim.sim_user_id ?? "Selecteer gebruiker"}
              loading={loading}
            />
          </div>
          {simState.sim.sim_user_email ? (
            <div>
              <Cta
                text=""
                loading={loading}
                style="dangernbg"
                onClick={handleRemoveUser}
                icon={<XCircle size={18} />}
              />
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Tariefplan
            </Typography>
          </div>
          <div className="col-span-3">
            {simState.sim.sim_trf_id ? (
              <>
                <Typography tag="span" type="default">
                  {simState.sim.tariefplan?.trf_naam}
                </Typography>
                <RatePlanInfo ratePlan={simState.sim.tariefplan} />
              </>
            ) : (
              ""
            )}
          </div>
        </div>

        {simState.sim.sim_options?.length > 0 ? (
          <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
            <div className="col-span-2">
              <Typography tag="span" type="default">
                Actieve optie
              </Typography>
            </div>
            <div className="col-span-3">
              <Typography tag="span" type="default">
                {simState.sim.sim_options[0]?.trf_naam}
              </Typography>
              <RatePlanInfo ratePlan={simState.sim.sim_options[0]} />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Label
            </Typography>
          </div>
          <div className="col-span-2">
            <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_label"
                value={simState.sim.sim_label}
                defaultValue={simState.sim.sim_label || ""}
                placeholder={t("Label voor simkaart")}
              />
            </Typography>
          </div>
        </div>
        <div className="grid grid-cols-5 px-5 py-3 justify-center items-center">
          <div className="col-span-2">
            <Typography tag="span" type="default">
              Nota
            </Typography>
          </div>
          <div className="col-span-2">
            <Typography tag="span" type="default">
              <InputField
                type="text"
                onBlur={changeInput}
                name="sim_nota"
                value={simState.sim.sim_nota}
                defaultValue={simState.sim.sim_nota || ""}
                placeholder={t("Nota voor simkaart")}
              />
            </Typography>
          </div>
        </div>
      </Box>
      <Tabs tabs={tabs} />
    </div>
  );
};

export default SimDetailContainer;
