import React, { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../state/store";
import { SimcardService } from "../../services/simcards/Simcards";
import { setAside } from "../../state/component/AsideSlice";
import { setCurrentProject } from "../../state/project/ProjectSlice";
import { ProjectsService } from "../../services/project/Projects";
import { addToast } from "../../state/toast/ToastSlice";
import { MailService } from "../../services/mails/Mails";

import SimOnboardingForm from "../../components/forms/users/SimOnboardingForm";

const SimOnboarding: FC = () => {
  // const { simcards } = useSelector((state: RootState) => state.simcards);
  const [simcardDetails, setSimcardDetails] = useState([
    { sim: "", tariefplan: "" },
  ]);

  const task = useSelector((state: RootState) => state.aside.data.task);
  const project = useSelector(
    (state: RootState) => state.project.currentProject
  );
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const branchState = useSelector((state: RootState) => state.branch);

  const projectService = new ProjectsService();
  const mailService = new MailService();
  const simcardService = new SimcardService();

  const dispatch = useDispatch();

  const handleSubmit = async (formData) => {
    const today = new Date();

    try {
      await simcardService.updateOrCreateProximusSim(
        {
          sim_unique_nr: formData.get("isESim")
            ? ""
            : formData.get("simcards[sim]"),
          sim_user_id: Number(formData.get("user_id")),
          sim_trf_id: Number(formData.get("simcards[tarifId]")),
          sim_status: "Aanvraag activatie",
          sim_type: formData.get("isESim") ? "eSim" : "Regular",
        },
        companyId
      );

      mailService.sendMail(
        process.env.GATSBY_DEFAULT_EMAIL,
        formData.get("transferType"),
        "email.aanvragen.sim_test",
        formData
      );

      const assignments = simcardDetails.map((simcard) => ({
        proj_omschrijving: `Simkaart: ${simcard.sim} met tariefplan: ${
          simcard.tariefplan
        } aan de gebruiker toewijzen, vanaf${
          formData.selectedDate
            ? ` ${formData.selectedDate.format("DD-MM-YYYY")}.`
            : ` ${today.toLocaleDateString()}.`
        }`,
        projFaseRegel_type: "cmctaak",
        projFaseRegel_extra: "CMC",
      }));

      dispatch(
        setAside({
          active: 0,
        })
      );

      const project = await projectService.addAssignments(
        assignments,
        task.phase_id,
        task.rule_id,
        companyId
      );

      dispatch(
        addToast({
          description: "De taak is succesvol afgerond.",
          position: "bottomRight",
          style: "success",
        })
      );

      dispatch(setCurrentProject(project));
    } catch (error) {
      console.error("Error adding assignments:", error);
    }
  };

  return (
    <div>
      <SimOnboardingForm onSubmit={handleSubmit} />
    </div>
  );
};

export default SimOnboarding;
