import React, { useState } from "react";
import DateInput from "../../inputs/dates/DateInput";
import dayjs from "dayjs";
import Form from "../Form";
import { useDispatch, useSelector } from "react-redux";
import { TicketService } from "../../../services/tickets/Tickets";
import { updateAside } from "../../../state/component/AsideSlice";
import { setSimcard } from "../../../state/simdata/SimcardsSlice";
import Typography from "../../typography/Typography";
import { useTranslation } from "react-i18next";
import { RootState } from "../../../state/store";

const DisableSimcard = () => {
  const { t } = useTranslation();
  const [date, setDate] = useState(dayjs());
  const [loading, setLoading] = useState(false);
  const sim = useSelector((state: RootState) => state.aside.data);
  const companyId = useSelector(
    (state: RootState) => state.companies.selectedCompany?.id
  );
  const ticketService = new TicketService();
  const dispatch = useDispatch();

  const handleChange = (date) => {
    setDate(date);
  };

  const handleSubmit = () => {
    setLoading(true);

    const prop = {
      sim_status: t("Opzeggen"),
    };
    dispatch(updateAside({ props: prop, object: "sim" }));
    dispatch(setSimcard({ props: prop, key: sim.sim.key }));
    setLoading(false);

    ticketService.createTicketZendesk(companyId, {
      subject: `Opzeggen simkaart ${sim.sim.sim_unique_nr}`,
      description: `Opzeggen simkaart: ${sim.sim.sim_unique_nr} vanaf ${date}`,
      department: "Telecom",
      linkedResource: {
        id: sim.sim.sim_unique_nr,
        type: "TELECOM",
      },
      // meta: sim.sim,
    });
  };

  return (
    <Form
      buttonLabel="Opzeggen"
      handleSubmit={handleSubmit}
      gridSize="grid-cols-2"
      loading={loading}
      buttonStyle="danger"
    >
      <div className="block mt-2 mb-2">
        <Typography tag="label" type="label">
          informatie opzeggen simkaart
        </Typography>
        <div className="mb-2"></div>
        <Typography tag="label" type="label">
          Start en einddatum
        </Typography>
        <div className="block">
          <DateInput onChange={handleChange} value={date} />
        </div>
      </div>
      <div className="mb-3"></div>
    </Form>
  );
};
export default DisableSimcard;
