import React, { useEffect, useState } from "react";
import PieDiagram from "../../../components/charts/Pie";
import { SimcardService } from "../../../services/simcards/Simcards";
import { GetDateMonthBack } from "../../../utils/dates/GetDateMonthBack";
import Box from "../../../components/boxes/Box";
import LoadingSpinner from "../../../components/buttons/LoadingSpinner";

const SimUserUsage = (phoneNumbers: object) => {
  const service = new SimcardService();
  const [pieData, setPieData] = useState([]);

  useEffect(() => {
    setPieData([]);
    const bruto = async () => {
      const lastUsageDate = await GetDateMonthBack();
      service
        .getBruto({
          years: [lastUsageDate.year],
          months: [lastUsageDate.month],
          metricGroups: [
            "calls",
            "data",
            "mpay",
            "others",
            "sms",
            "subscriptions",
          ],
          ...phoneNumbers,
        })
        .then((res) => {
          setPieData(res);
        });
    };
    bruto();
  }, [phoneNumbers]);

  return (
    <>
      {pieData.items > 0 ? (
        <Box type="bordered" size="medium" gridSize="grid-1">
          <PieDiagram data={pieData} />
        </Box>
      ) : pieData.errorCode ? (
        "Geen data gevonden"
      ) : (
        <LoadingSpinner />
      )}
    </>
  );
};

export default SimUserUsage;
