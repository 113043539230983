import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface License {
  [licenseId: string]: {
    quantity: number;
    name: string;
  };
}

interface LicenseState {
  licenses: License[];
}

const initialState: LicenseState = {
  licenses: [],
};

const licenseSlice = createSlice({
  name: "license",
  initialState,
  reducers: {
    addLicense: (state, action: PayloadAction<License>) => {
      const newLicense = action.payload;
      Object.keys(newLicense).forEach((id) => {
        const existingLicense = state.licenses[id];
        if (existingLicense) {
          existingLicense.quantity += newLicense[id].quantity;
        } else {
          state.licenses[id] = { ...newLicense[id] };
        }
      });
    },

    removeLicense: (state, action: PayloadAction<string>) => {
      state.licenses = state.licenses.filter(
        (license) => license.id !== action.payload
      );
    },
    updateLicenseQuantity: (
      state,
      action: PayloadAction<{ id: string; quantity: number }>
    ) => {
      const { id, quantity } = action.payload;
      const license = state.licenses[id];
      if (license) {
        state.licenses[id] = {
          ...license,
          quantity: Math.max(license.quantity - 1, 0),
        };
      }
    },
    clearLicenses: (state) => {
      state.licenses = [];
    },
  },
});

export const {
  addLicense,
  removeLicense,
  updateLicenseQuantity,
  clearLicenses,
} = licenseSlice.actions;
export default licenseSlice.reducer;
