interface Search {
  url: string;
  search: object;
}

export const AppendSearchParams = (url, search) => {
  const searchUrl = new URL(url);
  if (search) {
    const params = Object.keys(search) as (keyof typeof search)[];

    params.forEach(function (key, index) {
      if (search[key] !== undefined) {
        searchUrl.searchParams.append(key, search[key]);
      }
    });
  }

  return searchUrl.toString();
};
