import { UserRequestedLicense } from "../../interfaces/UserRequestedLicense";
import apiClient from "../../../api-client";

export class LicenseService {
  public async getLicenses(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/companylicenses`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all companylicenses: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getAllLicenses(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/licenses`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all licenses: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserLicenses(companyId: number, userId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/user/${userId}/licenses`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all userlicenses: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async addUser(licenseId: number, userId: string, companyId: number) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicenses`,
        { id_bedrijf_licentie: licenseId, id_user: userId }
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error adding user to license: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async removeUser(licenseId: number, companyId: number) {
    try {
      const response = await apiClient.delete(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicenses/${licenseId}`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error removing user from license: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async updateLicense(
    companyId: number,
    licenseId: number,
    data: { [key: string]: any }
  ) {
    try {
      const response = await apiClient.put(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicense/${licenseId}`,
        data
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error updating license: ${error.response?.statusText || error.message}`
      );
    }
  }

  public async getCategories(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/license/categories`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching license categories: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createLicenseComplete(
    companyId: number,
    data: {
      lic_licentie: string;
      lic_type?: string;
      lic_informatie?: string;
      aantal?: number;
      prijs?: number;
      periode?: string;
    }
  ) {
    try {
      const response = await apiClient.post(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/completelicense`,
        data
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error creating complete license: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async createUserRequestedLicense(
    data: Omit<UserRequestedLicense, "id" | "created_at" | "updated_at">
  ) {
    const response = await fetch(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${data.company_id}/userlicense/request`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error creating user requested license: ${response.statusText}`
      );
    }

    const result = await response.json();
    return result;
  }

  public async getAllUserRequestedLicenses(companyId: number) {
    try {
      const response = await apiClient.get(
        `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicense/request`
      );

      return response.data;
    } catch (error: any) {
      throw new Error(
        `Error fetching all user requested licenses: ${
          error.response?.statusText || error.message
        }`
      );
    }
  }

  public async getUserRequestedLicenseByUserId(
    userId: number,
    companyId: number
  ) {
    const response = await fetch(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicense/request/user/${userId}`,
      { credentials: "include" }
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching user requested license: ${response.statusText}`
      );
    }

    const result = await response.json();
    return result;
  }

  public async getUserRequestedLicenseById(id: number, companyId: number) {
    const response = await fetch(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicense/request/${id}`,
      { credentials: "include" }
    );

    if (!response.ok) {
      throw new Error(
        `Error fetching user requested license: ${response.statusText}`
      );
    }

    const result = await response.json();
    return result;
  }

  public async updateUserRequestedLicense(
    id: number,
    company_id: number,
    data: Partial<
      Omit<UserRequestedLicense, "id" | "created_at" | "updated_at">
    >
  ) {
    const response = await fetch(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${company_id}/userlicense/request/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...data,
        }),
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error updating user requested license: ${response.statusText}`
      );
    }

    const updatedLicense = await response.json();
    return updatedLicense;
  }

  public async deleteUserRequestedLicense(id: number, companyId: number) {
    const response = await fetch(
      `${process.env.GATSBY_SSO_BACKEND_API_URL}/company/${companyId}/userlicense/request/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    if (!response.ok) {
      throw new Error(
        `Error deleting user requested license: ${response.statusText}`
      );
    }

    return response.ok;
  }
}
