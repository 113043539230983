import React, { FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import TextHeader from "../../../components/headers/TextHeader";
import BranchForm from "../../../components/forms/branches/BranchForm";

const BranchDetailContainer: FC = () => {
  const branchstate = useSelector((state: RootState) => state.aside.data);
  return (
    <div>
      <TextHeader title={branchstate.branch.ves_name} subtitle={"Vestiging"} />
      <BranchForm branch={branchstate.branch} />
    </div>
  );
};

export default BranchDetailContainer;
