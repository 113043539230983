import React, { FC } from "react";

import TooltipArrow from "../../boxes/Tooltip";
import Typography from "../../typography/Typography";
import CurrencyFormatter from "../../../utils/text/CurrencyFormatter";

type Props = {
  price: number;
  title: string;
};

const TooltipMetricGroups: FC<Props> = ({ price, title }) => {
  return (
    <TooltipArrow>
      <div className="block">
        <Typography tag="span" type="default">
          {title}
        </Typography>
      </div>
      <Typography tag="span" type="subtitle">
        <CurrencyFormatter price={price} />
      </Typography>
    </TooltipArrow>
  );
};

export default TooltipMetricGroups;
