import React, { FC, ReactNode, useEffect, useRef, useState } from "react";
import { Tabs as AntTabs, TabsProps } from "antd";
import { useTranslation } from "react-i18next";

type Props = {
  tabs: {
    nav: string;
    component: ReactNode[];
  }[];
  operations?: ReactNode;
};

const Tabs: FC<Props> = ({ tabs, operations }) => {
  const transformTabsToItems = (tabs: Props["tabs"]) => {
    const { t } = useTranslation();
    return tabs.map((tab, index) => ({
      key: String(index + 1),
      label: t(tab.nav),
      children: tab.component,
    }));
  };

  const items: TabsProps["items"] = transformTabsToItems(tabs);

  return (
    <AntTabs
      tabBarStyle={{ paddingLeft: "10px" }}
      tabBarExtraContent={operations}
      defaultActiveKey="1"
      items={items}
    />
  );
};

export default Tabs;
