import React, { FC, useEffect, useState } from "react";
import { ShopService } from "../../services/shop/Shop";
import LoadingImage from "../loading/LoadingImage";

type ImageProps = {
  productId: number;
  style: string;
  baseURL?: string;
};

const styles = {
  default: "h-44 w-auto mx-auto my-5",
  big: "w-1/2 xl:w-1/3 2xl:w-2/3 mx-auto",
  small: "h-28 w-auto mx-auto p-3 border border-blue-700 rounded-lg",
  cart: "max-h-28 w-auto p-3",
};

const shopService = new ShopService();

const OverviewImage: FC<ImageProps> = ({
  productId,
  style = "default",
  baseURL = "https://shop.cmcgroup.be/assets/upload/product/",
}) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);

  const fetchData = async () => {
    try {
      const image: Image = await shopService.getProductImage(productId);
      setImageUrl(`${baseURL}${image[0].name}.jpg`);
    } catch (error) {
      console.error("Error fetching product image:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [productId]);

  return imageUrl ? (
    <img src={imageUrl} alt="" className={styles[style]} />
  ) : (
    <LoadingImage />
  );
};

export default OverviewImage;
