import { FC } from "react";
import TextHeader from "../../../components/headers/TextHeader";
import React from "react";
import EditGroupForm from "../../../components/forms/groups/EditGroupForm";

const GroupCreateContainer: FC = () => {
  return (
    <div>
      <TextHeader title="Aanmaken" subtitle="Groep" />
      <EditGroupForm group={{}}></EditGroupForm>
    </div>
  );
};

export default GroupCreateContainer;
