import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface Tag {
  tag: string;
}

interface Hardware {
  ordSer_id: number;
  tags: Tag[];
  // other hardware properties
}

interface HardwareState {
  hardware: Hardware[];
}

const initialState: HardwareState = {
  hardware: [],
};

const hardwareSlice = createSlice({
  name: "hardware",
  initialState,
  reducers: {
    setHardware: (state, action: PayloadAction<Hardware[]>) => {
      state.hardware = action.payload;
    },
    updateHardware: (
      state,
      action: PayloadAction<{ id: number; updatedData: Partial<Hardware> }>
    ) => {
      const { id, updatedData } = action.payload;
      const existingHardware = state.hardware.find(
        (hardware) => hardware.ordSer_id === id
      );
      if (existingHardware) {
        Object.assign(existingHardware, updatedData);
      }
    },
    createHardware: (state, action: PayloadAction<Hardware>) => {
      state.hardware.push(action.payload);
    },
    deleteHardware: (state, action: PayloadAction<number>) => {
      state.hardware = state.hardware.filter(
        (hardware) => hardware.ordSer_id !== action.payload
      );
    },
    createTag: (
      state,
      action: PayloadAction<{ hardwareId: number; tag: string }>
    ) => {
      const { hardwareId, tag } = action.payload;
      const hardware = state.hardware.find(
        (item) => item.ordSer_id === hardwareId
      );
      if (hardware) {
        hardware.tags.push(tag); // Assign a temporary id, you can adjust it based on your backend logic
      }
    },
    deleteTag: (
      state,
      action: PayloadAction<{ hardwareId: number; tagId: number }>
    ) => {
      const { hardwareId, tagId } = action.payload;
      const hardware = state.hardware.find(
        (item) => item.ordSer_id === hardwareId
      );
      if (hardware) {
        hardware.tags = hardware.tags.filter((tag) => tag.id !== tagId);
      }
    },
  },
});

export const {
  setHardware,
  updateHardware,
  createHardware,
  deleteHardware,
  createTag,
  deleteTag,
} = hardwareSlice.actions;

export default hardwareSlice.reducer;
